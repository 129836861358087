export const syncMenu = {
	title: "Menu de livraison ({{externalStoreType}})",
	description:
		"Sélectionnez dans la liste de Zig le produit à associer avec l'élément de {{externalStoreType}}. Les éléments deviendront uniques",
	menuNameByExternalType: "Menu de livraison ({{externalStoreType}})",
	transferProducts: {
		warning:
			"{{amount}} produits n'ont pas été synchronisés. Voulez-vous les importer dans le menu de livraison?",
		description:
			"Sélectionnez les éléments que vous souhaitez importer, ou terminez pour continuer.",
		amountItemsSelected: "<strong>{{amount}}</strong> éléments sélectionnés",
		completeBtn: "Terminer sans importer",
		importItemsBtn: "Importer les éléments",
	},
	bindProduct: {
		searchPlaceholder: "Rechercher un autre produit pour synchroniser",
		allProducts: "Tous les produits",
		notProductFound: "Aucun produit trouvé",
		errorProductFound: "Une erreur s'est produite lors de la recherche de produits",
		similarity: {
			title: "Aucun produit similaire trouvé",
			description:
				"Recherchez dans la barre de recherche ci-dessus ou passez au produit suivant.<br/>Vous pourrez importer les produits sans similitude à la fin de la synchronisation.",
		},
		saveLater: "Enregistrer et continuer plus tard",
		undo: "Annuler l'action précédente",
		completeSync: "Terminer la synchronisation",
		skuInfo: {
			title: "Le SKU de ce produit sera remplacé",
			description:
				"Le SKU de ZIG (produit sélectionné à côté) sera superposé dans les deux menus. C'est cela qui permettra une gestion centralisée.",
		},
		undoModal: {
			title: "Annuler la synchronisation?",
			description:
				"En confirmant, vous reviendrez au produit précédent et la synchronisation sera annulée.",
			okText: "Je veux annuler",
			cancelText: "Annuler",
		},
	},
	productCard: {
		descriptionLabel: "Description",
		active: "Actif",
		inactive: "Inactif",
	},
	transferProductSuccess: {
		title: "Intégration terminée",
		description:
			"Votre intégration est terminée et vos produits ont été synchronisés avec succès!",
		button: "Voir les produits et les menus",
	},
	syncMenuSuccess: {
		title: "Intégration terminée",
		description:
			"Votre intégration est terminée et vos produits ont été synchronisés avec succès!",
		button: "Voir les produits et les menus",
		continue: "Voir les produits non importés",
	},
	errorContent: {
		title: "Impossible d'importer les produits",
		description:
			"Quelque chose de inattendu s'est produit et nous n'avons pas pu terminer l'importation des produits. Terminez votre intégration et finalisez votre menu plus tard.",
		completeBtn: "Terminer la synchronisation sans importer",
		retryBtn: "Réessayer l'importation",
	},
	onboarding: {
		step1: {
			title: "Nous allons afficher un produit à la fois",
			description:
				"Chaque produit importé sera affiché à côté des suggestions équivalentes dans le catalogue de produits de ZIG. Vous sélectionnerez le produit idéal à unifier.",
		},
		step2: {
			title: "Un seul SKU",
			description:
				"En sélectionnant le produit, le SKU de ZIG prévaudra et vous pourrez gérer centralement sur notre tableau de bord.",
		},
		step3: {
			title: "Importez ce qui n'est pas encore dans Zig",
			description:
				"Lorsque vous aurez terminé de synchroniser les articles équivalents, vous pourrez importer les produits qui n'ont pas été identifiés dans le catalogue de ZIG. Ainsi, vous aurez tous vos produits en un seul endroit!",
		},
		skipBtn: "Passer",
		submitBtn: "Commencer la synchronisation",
	},
};
