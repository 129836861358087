import { promotionInfo } from "./promotion-info";
import { awards } from "./awards";
import { resume } from "./resume";
import { sideTab } from "./side-tab";

export const promotions = {
	title: "Promotions",
	filter: {
		label: "Filter",
		all: "All",
		active: "Active",
		inactive: "Inactive",
	},
	search: "Search by promotion name",
	empty: "The search found no results",
	withoutPromotions: "There are no promotions registered",
	create: "Create promotion",
	createPromotion: {
		promotionInfo,
		goals: {
			title: "Goals",
			description:
				"Goals are parameters that will determine what your customer needs to do to earn the promotion.",
		},
		awards,
		usageConditions: {
			title: "Terms of use",
			description:
				"In this step, you can define promotion usage parameters. When the customer wins the promotion, he can only use it if he follows these conditions of use.",
			warning:
				'The terms of use for "Buy and Win" are also used to win the promotion. Expiration does not apply to "Buy & Earn".',
		},
		resume,
	},
	card: {
		subtitle: "{{discount}} discount",
		withoutDiscount: "No discount",
		expiresAt: "Expires in {{date}} Day(s)",
		noExpirationDate: "No expiration date",
		active: "Active",
		inactive: "Inactive",
	},
	sideTab,
	selected: {
		title: "Promotion name",
		active: "Active",
		inactive: "Inactive",
		clientList: "Customer list",
		clientListModal: {
			feature: "The 'Buy and Win' feature must be active",
			title: "Customer List {{promotionName}}",
			addClient: {
				label: "Add customers to list",
				placeholderForeign: "Enter customer phone number",
				placeholder: "Enter customer Document",
				addClient: "Add client",
				foreign: "Foreign",
			},
			search: "Search by name or CPF",
			searchForeign: "Search by name or phone",
			remove: "Remove selected customers",
			columns: {
				name: "Name",
				cpf: "CPF",
				cpfForeign: "Document",
				phone: "Telephone",
			},
			error: "Unable to add the customers below:",
		},
		copy: "Create copy",
		discountValue: {
			title: "{{discount}} discount",
			unavailable: "Unavailable",
		},
		maxUses: {
			title: "{{uses}} uses per person",
			withoutLimit: "No single use limit",
		},
		expirationTime: {
			title: "Expires in: {{date}} Days",
			noExpires: "No expiration date",
		},
		productsList: {
			title: "PRODUCTS ON PROMOTION",
			all: "All the products",
			unavailable: "Product Unavailable",
			seeMore: "View more",
			seeLess: "See less",
		},
		erasePromotion: {
			title: "Delete promotion",
			confimation: "Are you sure you want to delete the promotion?",
		},
	},
};
