import { TransactionalHistoryTableTranslations } from "./transactional-history-table-pt-br";

export const TRANSACTIONAL_HISTORY_TABLE_EN_US: TransactionalHistoryTableTranslations = {
	columns: {
		transaction: "Transaction",
		bonusCategoryName: "Bonus Category",
		seller: "Seller",
		zigCode: "ZigTag",
		value: "Value",
		id: "ID",
		time: "Transaction Time",
		sync: "Sync",
	},
	noCategory: "No Category",
	withoutInfo: "Without Information",
	noCard: "No Card",
};
