import { merge } from "lodash-es";
import frJson from "./fr.json";
import { PURCHASE_INVOICE_FR_FR } from "#pages/place/purchase-invoice/translations/purchase-invoice-fr-fr";
import { IMPORT_FR_FR } from "#pages/place/purchase-invoice/import-invoice/translations/import-fr-fr";
import { CONCILIATION_FR_FR } from "#pages/backoffice/conciliation/translations/conciliation-fr-fr";
import { delivery } from "./place/delivery";
import { BONUS_CATEGORIES_FR_FR } from "#pages/place/bonus-categories/translations/bonus-categories-fr-fr";
import { USER_BONUS_TABLE_FR_FR } from "#components/tables/reports/user-bonus-table/translations";
import { USED_BONUS_REPORT_FR_FR } from "#pages/place/financial-reports/bonus-report/used-bonus-report/translations";
import { BONUS_REPORT_FR_FR } from "#pages/place/financial-reports/bonus-report/translations";
import { BONUS_REPORT_FOR_PLACE_FR_FR } from "#pages/place/financial-reports/bonus-report-for-place/translations";
import { BONUS_REPORT_AT_EVENT_FR_FR } from "#pages/event/event-reports/bonus-report-at-event/translations";
import { TRANSACTIONAL_HISTORY_TABLE_FR_FR } from "#pages/event/event-client-detail/client-info/transactional-history/transactional-history-table/translations";
import { TRANSACTIONAL_HISTORY_FR_FR } from "#pages/event/event-client-detail/client-info/transactional-history/translations";

const fr = merge(frJson, {
	place: {
		purchaseInvoiceV2: PURCHASE_INVOICE_FR_FR,
		import: IMPORT_FR_FR,
		backoffice: { conciliation: CONCILIATION_FR_FR },
		delivery,
		bonusCategories: BONUS_CATEGORIES_FR_FR,
		usedBonusReport: USED_BONUS_REPORT_FR_FR,
		bonusReport: BONUS_REPORT_FR_FR,
		userBonusTable: USER_BONUS_TABLE_FR_FR,
		bonusReportForPlace: BONUS_REPORT_FOR_PLACE_FR_FR,
	},
	event: {
		userBonusTable: USER_BONUS_TABLE_FR_FR,
		bonusReportAtEvent: BONUS_REPORT_AT_EVENT_FR_FR,
		transactionalHistory: TRANSACTIONAL_HISTORY_FR_FR,
		transactionalHistoryTable: TRANSACTIONAL_HISTORY_TABLE_FR_FR,
	},
});

export { fr };
