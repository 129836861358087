export const clientArea = {
	title: "Dashboards",
	places: "Locais",
	org: "Organização",
	searchPanel: "Nome do painel",
	noPanels: "Sem painéis disponíveis.",
	noPanelsForFilter: "Sem painéis correspondentes aos filtros.",
	autoRefresh: "Atualização automática.",
	fullScreen: "Tela cheia",
	autoUpdatesOn: "Atualizações automáticas ativadas",
	autoUpdatesOff: "Atualizações automáticas desativadas",
	rotateDevice: "Por favor gire seu dispositivo",
	otherPanels: "Outros",
	subtitle:
		"Tenha acesso centralizado aos painéis de dados essenciais. Aqui, você encontra insights atualizados para análise e tomada de decisão, tudo em um só lugar.",
};
