export const TRANSACTIONAL_HISTORY_TABLE_PT_BR = {
	columns: {
		transaction: "Transação",
		bonusCategoryName: "Categoria de bônus",
		seller: "Vendedor",
		zigCode: "ZigTag",
		value: "Valor",
		id: "id",
		time: "Horário da transação",
		sync: "Sincronização",
	},
	noCategory: "Sem categoria",
	withoutInfo: "Sem informação",
	noCard: "Sem cartão",
};

export type TransactionalHistoryTableTranslations = typeof TRANSACTIONAL_HISTORY_TABLE_PT_BR;
