export const deliveryMenus = {
	header: {
		mainTitle: "Menus",
		mainDescription: "Visualisez, créez et éditez des menus de livraison.",
		editDescription: "Visualisez et éditez votre menu.",
	},
	menuList: "Menu de livraison",
	status: {
		active: "Actif",
		pendingSync: "Synchronisation en attente",
		pendingImport: "Importation en attente",
		inactive: "Inactif",
		synced: "Synchronisé",
	},
	tabs: {
		importedMenus: "Menus importés",
		whiteLabelMenus: "Whitelabel",
	},
	menuCard: {
		message: "Ouvert 24h/24",
		at: "à",
		delivery: "Livraison",
		table: "Commande à table",
		businessHour: "Horaires d'ouverture",
		type: "Type",
		importedAt: "Importé le",
		createdAt: "Créé le",
		products: "Produits",
		numberOfProducts: "{{ number }} Produits",
		unsyncedProducts: "produits non synchronisés",
		notImportedProducts: "produits non importés",
	},
	actions: {
		seeMenu: "Voir le menu",
		continueImport: "Continuer l'importation",
		continueSync: "Continuer la synchronisation",
		create: "Créer un nouveau",
		import: "Importer un menu",
		search: "Rechercher des menus",
		manualMode: "Mode manuel",
		editMenu: "Éditer le menu",
		deleteMenu: "Supprimer le menu",
		addProduct: "Ajouter un produit",
		searchProduct: "Rechercher un produit",
	},
	categories: {
		appetizers: "Entrées",
		mainCourses: "Plats principaux",
		desserts: "Desserts",
		nonAlcoholicDrinks: "Boissons non alcoolisées",
		alcoholicDrinks: "Boissons alcoolisées",
	},
	emptyState: {
		title: "Menu de livraison",
		message: "Il semble que vous n'avez encore créé aucun menu",
		create: "Créer un menu",
		integration: "Importer un menu",
	},
};
