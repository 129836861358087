export const importMenu = {
	title: "Configurer la livraison et les intégrations",
	description:
		"Intégrez avec un partenaire de livraison et centralisez votre gestion dans Zig.",
	info:
		"Il est possible que certains produits aient des éléments complémentaires et facultatifs. Vous pourrez examiner les détails et apporter des ajustements dans 'Produits et Menus' après importation.",
	selectAllCategories: "Sélectionner toutes les catégories",
	searchCategory: "Rechercher une catégorie",
	amountItemsSelected: "<strong>{{amount}}</strong> éléments sélectionnés",
	cancelBtn: "Annuler",
	importItensSelected: "Importer les éléments sélectionnés",
	notProductFound: "Aucun produit trouvé",
	returnToMenu: "Retourner au menu",
	menuNameByExternalType: "Menu de livraison ({{externalType}})",
	importMenuError: {
		title: "Impossible d'importer votre menu",
		description:
			"Un événement inattendu s'est produit et nous n'avons pas pu terminer l'importation. Terminez votre intégration et finalisez votre menu plus tard.",
		completeWithoutMenuBtn: "Terminer l'intégration sans menu",
		retryBtn: "Essayer d'importer à nouveau",
	},
	importMenuSuccess: {
		title: "Votre menu a été importé avec succès",
		description:
			"Vous pouvez gérer vos menus dans l'onglet ,,Menus,, de ce même module, ainsi que gérer vos produits dans le module ,,Produits et Menus,,.",
		viewProductsBtn: "Voir les produits",
	},
	integrationSuccess: {
		title: "Intégration terminée",
		description:
			"Votre intégration a été terminée avec succès ! Vous pouvez gérer vos menus plus tard dans le module ,,Produits et Menus,,.",
		viewProductsAndMenusBtn: "Voir les produits et les menus",
	},
	loadingMessage: "En attente de l'identification des éléments de votre menu",
	loadingMutation: "En attente de la finalisation de l'intégration",
	active: "Actif",
	inactive: "Inactif",
};
