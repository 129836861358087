import { UserBonusTableTranslations } from "./user-bonus-table-pt-br";

export const USER_BONUS_TABLE_FR_FR: UserBonusTableTranslations = {
	title: "Bonus",
	total: "Total de {{category}}",
	noCategory: "Sans catégorie",
	columns: {
		total: "Total",
		givenBy: "Donné par",
		client: "Employé Responsable",
		date: "Date",
		reason: "Raison",
		category: "Catégorie",
		receivedValue: "Valeur reçue",
		spentValue: "Valeur dépensée pendant la période",
		spentValueInOtherEvents: "Valeur dépensée pendant une autre période",
	},
	minimumConsumptionReport: {
		title: "Consommation anticipée",
	},
	filenameBonus: "bonus-accordé",
	filenameAnticipated: "consommation-anticipée",
	filenameConsumption: "consommation",
	select: "Sélectionnez une catégorie",
	selectNotFound: "Aucune catégorie trouvée",
};
