export const menu = {
	header: {
		search: "Buscar cardápios",
		create: "Criar Cardápio",
		manualMode: "Modo Manual",
	},
	menuList: "Cardápio para delivery",
	menuCard: {
		message: "funcionamento 24h",
		at: "às",
		delivery: "Delivery",
		table: "Pedido na mesa",
		integratedDelivery: "Delivery Integrado",
		businessHour: "Horário de funcionamento",
		type: "Tipo",
		active: "ativo",
		inactive: "inativo",
	},
	noMenu: {
		title: "Cardápio para delivery",
		message: "Parece que você ainda não tem nenhum cardápio criado",
		create: "Criar Cardápio",
	},
	warningNotification:
		"Menu criado, porém houve um problema no cadastro do horário de funcionamento:",
};
