import { BonusReportAtEventTranslations } from "./bonus-report-at-event-pt-br";

export const BONUS_REPORT_AT_EVENT_ES_MX: BonusReportAtEventTranslations = {
	title: "Bonificación",
	description: "Ver todos los informes de concesión y uso de bonificaciones",
	tabs: {
		given: "Bonificación Otorgada",
		used: "Bonificación Utilizada",
	},
};
