import { BonusCategoriesTranslations } from "./bonus-categories-pt-br";

export const BONUS_CATEGORIES_FR_FR: BonusCategoriesTranslations = {
	title: "Catégories de Bonus",
	notification: {
		create: "Catégorie de bonus créée avec succès",
		edit: "Catégorie de bonus modifiée avec succès",
		changeStatus: "Catégorie de bonus modifiée avec succès",
		delete: "Catégorie de bonus supprimée avec succès",
	},
	delete: {
		title: "Supprimer la catégorie de bonus",
		subtitle: "Êtes-vous sûr de vouloir supprimer cette catégorie de bonus ?",
		confirm: "Oui, supprimer",
		cancel: "Annuler",
	},
	header: {
		create: "Nouvelle catégorie de bonus",
		edit: "Modifier la catégorie de bonus",
		search: "Rechercher...",
		changeStatus: "Changer le statut",
		delete: "Supprimer la catégorie",
	},
	list: {
		title: "Catégories de bonus",
		status: {
			label: "Statut",
			active: "Actif",
			inactive: "Inactif",
		},
		category: "Catégorie",
		employees: "Autorisés",
		daysToExpire: "Expiration",
		days: "jours",
		trash: "Supprimer",
		options: {
			label: "Options",
			edit: "Modifier la catégorie",
			inactive: "Désactiver la catégorie",
			active: "Activer la catégorie",
		},
	},
	form: {
		name: {
			label: "Nom de la catégorie",
			required: "Le nom est obligatoire",
			placeholder: "Entrez le nom de la catégorie",
			maxLength: "Maximum de 30 caractères",
		},
		daysToExpire: {
			label: "Validité du bonus",
			required: "Champ obligatoire",
			placeholder: "Jours",
			positive: "Le nombre de jours doit être positif",
			maxValue: "Maximum de 365 jours",
		},
		employees: {
			label: "Employés autorisés",
			placeholder: "Sélectionner les employés",
			validation: "Sélectionnez au moins un employé",
		},
	},
};
