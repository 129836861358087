export const USER_BONUS_TABLE_PT_BR = {
	title: "Bônus",
	total: "Total de {{category}}",
	noCategory: "Sem categoria",
	columns: {
		total: "Total",
		givenBy: "Dado por",
		client: "Funcionário Responsável",
		date: "Data",
		reason: "Motivo",
		category: "Categoria",
		receivedValue: "Valor recebido",
		spentValue: "Valor gasto no período",
		spentValueInOtherEvents: "Valor gasto em outro período",
	},
	minimumConsumptionReport: {
		title: "Consumação antecipada",
	},
	filenameBonus: "bonus-concedido",
	filenameAnticipated: "consumacao-antecipada",
	filenameConsumption: "consumacao",
	select: "Selecione uma categoria",
	selectNotFound: "Nenhuma categoria encontrada",
};

export type UserBonusTableTranslations = typeof USER_BONUS_TABLE_PT_BR;
