export const kds = {
	emptyKdsDescription:
		"The KDS has not been created yet. When available, it will be displayed here.",
	editKds: "Your changes have been saved.",
	title: "KDS Settings",
	subtitle: "Configure the KDS to enhance and organize your kitchen operations.",
	createdAt: "Created at",
	seeKds: "View KDS",
	linkedBar: "Linked bar",
	basic: "Basic",
	main: "Main",
	general: "Gereral",
	mainTooltip:
		"The Main KDS centralizes order control, allowing you to change statuses, cancel, view cancellations, and track all orders.",
	basicTooltip:
		"The Basic KDS displays all orders directed to it with the status open and completed, without allowing changes or viewing cancellations.",
	confirmModal: {
		title: "You have unsaved changes",
		description:
			"To leave the page without losing your progress, save your changes. If you continue without saving, all changes will be lost.",
		close: "Leave without saving",
	},
	form: {
		name: "KDS Name",
		linkedBar: "Linked bar",
		status: "Status change",
		timeManagement: "Time management",
		readyTimeOrderLabel: "Time for an order to be considered ready",
		standardTimeOrderLabel: "Standard time per order (min.)",
		originOrder: "Order origin (Delivery and other integrations)",
		orderNumber: "Order number",
		customerName: "Customer name",
		saveChanges: "Save changes",
		generalSettings: "General settings",
		timeSettings: "Time settings",
		orderCardSettings: "Order card settings",
	},
	statusChangeTypes: {
		auto: "Automatically",
		manual: "Manually",
	},
	timeManagementTypes: {
		byProduct: "Use time per product from the integration",
		defaultTime: "Use a standard per order",
		noManagement: "Do not use time",
	},
	reports: {
		title: "Report",
		description: "Track the overall production performance of your KDS.",
		averageAssemblyOrder: "Average order assembly time",
		productionBySector: "Sector with the highest production time",
		top10FastestProducts: "Top 10 fastest products",
		slowestProducts: "Products with the longest production time",
		top10LongestOrders: "Top 10 longest orders",
		order: "Order",
		averageProductionTime: "Average production time of orders",
		highestSectorProduction: "Sector with the highest production time",
		orderTable: {
			orderNumber: "Order number",
			customer: "Customer",
			origin: "Origin",
			local: "Location",
			date: "Date/time",
		},
		resumeDrawer: {
			order: "Order",
			title: "Order summary",
			date: "Date and time",
			customer: "Customer",
			originOrder: "Order origin",
			locationOrder: "Order location",
			table: {
				quantity: "Qty",
				product: "Product",
				sector: "Sector",
				time: "Time",
			},
		},
		noItemFound: "No item found",
	},
};
