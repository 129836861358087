export const newAndEditProduct = {
	titles: {
		edit: "Edit Product",
		add: "Add Product",
		productDetails: "Product Details",
		hasFiscalData: "Tax data",
		hasMountableProduct: "Mountable product",
		hasProductRule: "Datasheet",
		hasProportionalProduct: "Product in g/ml",
		downloadQRCodeToSell: "Download QR Code to sell",
		releaseValue: "Release value",
	},
	duplicateButton: "Duplicate",
	errors: {
		productFeature: "The {{wrongFeature}} functionality is not populated correctly.",
		productFeatures: "The {{wrongFeatures}} features are not populated correctly.",
		requiredField: "This field is mandatory",
		proportionalQuantity: "The amount must be greater than 0",
		abvQuantity: "The percentage must be less than 100",
		name: "Use only valid characters",
		categoryId: "Choose or create a category",
		wrongSection: "Step {{wrongStep}} is not filled in correctly.",
		wrongSections: "Steps {{wrongSteps}} are not filled in correctly.",
		productRules: "There are unfilled inputs",
		newCategory: "You will create a new category",
		length: "Máx. {{length}} characters",
		fiscalCode: "Only numbers, dots and letters",
	},
	forms: {
		details: {
			name: "Product's name",
			type: "Product Type",
			selectType: "Select a product type",
			measurement: "Stock controlled by",
			category: "Category",
			menuCategory: "Menu Category",
			priceIn: "Price in",
			price: " Price",
			variablePrice: "Variable price",
			picture: "Photograph",
			description: "Description",
			hasAlcohol: "Contains Alcohol",
			hasFiscalData: "Tax data",
			fiscalDataMessage: "The product code will be generated automatically",
			hasMountableProduct: "Mountable product",
			hasProductRule: "Datasheet",
			hasProportionalProduct: "Product in g/ml",
			isHiddenFromApp: "Do not display product in ZigApp application",
			syncWithBeerIntegration: "Synchronize with {{integration}}",
			beerBrand: "Brand",
			beerStyle: "Beer Style",
			beerABV: "ABV - Alcohol Content",
			beerIbu: "IBU - International Bitterness Units",
			beerIntegrationPlaceFeatureWarning:
				"Disabled function. Please contact support to activate it in the admin.",
			beerIntegrationDisabledWarning:
				"It is not possible to disable this function with 'Sync with {{integration}}' active.",
			beerIntegrationEnabledWarning:
				"It is not possible to enabled this function with 'Sync with {{integration}}' active.",
			productReturn: "Returnable Product",
			isSupplyToggle: "Supply",
			conversionRule: {
				unit: "Unit",
				amount: "Amount",
				unitMeansure: "Unit of measure",
				placeholderSelect: "Select",
				convertionName: "Conversion rule",
				title: {
					unitDefault: "Default unit of measure",
					unitConvertion: "Conversion rule",
				},
				field: {
					quantity: "Quantity",
					unitMeansure: "Unit of measure",
				},
				resumeDescription: "equals:",
				infoConvertionRule:
					"Creates a second unit of measure for your product, allowing it to be handled in different ways in your inventory.",
				warningConvertionRule:
					"The conversion rule can only be enabled when the product is controlled by unit.",
			},
		},
		fiscal: {
			code: "SKU",
			codeErrorMessage: "If not filled in, the code will be generated automatically",
			ncm: "NCM",
			cest: "CEST",
			fiscalProfile: "Tax profile",
			fiscalGroup: "Tax group",
		},
		mountable: {
			step: "Stage",
			addStep: "Add Step",
			infos: {
				label: "Information",
				title: "Title",
				titleErrorMessage: "Invalid name",
				precification: "Pricing",
				precificationErrorMessage: "Choose a method",
				precificationSelect: {
					label: "Choice...",
					sum: "Additional",
					sumTooltip: "Every item added will be incremented in the product value",
					average: "Average",
					averageTooltip: "The average of the selected items will be incremented",
					higher: "Highest value",
					higherTooltip:
						"The highest value among the items will be added to the product value",
				},
				min: "Min",
				tooltipMin: "Minimum items to be selected.",
				max: "Max",
				tooltipMax: "Maximum items to be selected.",
				invalidValue: "Invalid value",
				validateMaxMinErrors: {
					maxValueZero: "The maximum value cannot be equal to zero",
					negativeValue: "Negative value is not allowed",
					maxBiggerThenMin: "Maximum value must be greater than the minimum value",
				},
			},
			items: {
				label: "Items",
				title: " Item",
				titleErrorMessage: "No items selected",
				value: "Value",
				valueErrorMessage: "Invalid additional value",
				maxQuantity: "Selectable max quantity",
				maxQuantityTooltip:
					"Maximum number of times this item can be selected in the section",
				storageCount: "Quantity reduced",
				storageCountErrorMessage: "Invalid quantity",
				storageCountTooltip: "Quantity to be deducted from stock per unit.",
				additionalItems: "Add another item",
				itemKey: "Item {{itemKey}}",
			},
			information: "INFORMATION",
			itemsTitle: "ITEMS",
			getTitleDialog: {
				disable: "Disable mountable item",
				activate: "Activate mountable item",
			},
			getDescriptionDialog: {
				disable:
					"Do you want to disable this item in the other mountable products it makes up?",
				activate:
					"Do you want to activate this item in the other mountable products it makes up?",
			},
		},
		productRule: {
			generatedQuantity: "Generated product quantities",
			input: "Input",
			quantity: "The amount",
			loss: "Loss",
			addInput: "Add another input",
		},
		proportionalValue: {
			measurement: "Measurable unit",
			quantity: "The amount",
			grams: "Grams",
			mililiters: "Milliliters",
		},
	},
	cancelButton: "Cancel",
	confirmButton: "Confirm",
	undefined: "undefined",
};
