import { TransactionalHistoryTranslations } from "./transactional-history-pt-br";

export const TRANSACTIONAL_HISTORY_EN_US: TransactionalHistoryTranslations = {
	sheet: {
		fileName: "transactional-history-of-",
		in: "-in-",
		title: "Transactional history of {{user}} in {{event}}",
		id: "ID",
		operation: "Operation",
		bonusCategoryName: "Bonus category",
		noCategory: "No category",
		employee: "Seller",
		value: "Value",
		date: "Date",
		hour: "Hour",
	},
};
