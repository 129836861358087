export const importMenu = {
	title: "Configurar delivery e integraciones",
	description: "Integrar con un partner de delivery y centralizar tu gestión en Zig.",
	info:
		"Algunos productos pueden tener items complementarios y opcionales. Podrás revisar más detalles y hacer ajustes en 'Productos y Menus' después de importar.",
	selectAllCategories: "Seleccionar todas las categorías",
	searchCategory: "Buscar categoría",
	amountItemsSelected: "<strong>{{amount}}</strong> items seleccionados",
	cancelBtn: "Cancelar",
	importItensSelected: "Importar items seleccionados",
	notProductFound: "No se encontró ningún producto",
	returnToMenu: "Volver al menú",
	menuNameByExternalType: "Menú de entrega ({{externalType}})",
	importMenuError: {
		title: "No fue posible importar tu menú",
		description:
			"Algo inesperado ocurrió y no pudimos completar la importación. Completa tu integración y finaliza tu menú más tarde.",
		completeWithoutMenuBtn: "Completar integración sin menú",
		retryBtn: "Intentar importar nuevamente",
	},
	importMenuSuccess: {
		title: "Tu menú fue importado con éxito",
		description:
			"Puedes gestionar tus menús en la pestaña ,,Menús,, de este mismo módulo, además de gestionar tus productos en el módulo ,,Productos y Menús,,.",
		viewProductsBtn: "Ver productos",
	},
	integrationSuccess: {
		title: "Integración completada",
		description:
			"Tu integración fue completada con éxito. Puedes gestionar tus menús más tarde en el módulo ,,Productos y Menús,,.",
		viewProductsAndMenusBtn: "Ver productos y menús",
	},
	loadingMessage: "Espere mientras identificamos los elementos de su menú",
	loadingMutation: "Espere mientras completamos la integración",
	active: "Activo",
	inactive: "Inactivo",
};
