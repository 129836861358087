import { BonusReportTranslations } from ".";

export const BONUS_REPORT_EN_US: BonusReportTranslations = {
	title: "Bonus",
	description: "View all bonus grant and usage reports",
	tabs: {
		given: "Bonus Granted",
		used: "Bonus Used",
	},
};
