import { BonusCategoriesTranslations } from "./bonus-categories-pt-br";

export const BONUS_CATEGORIES_ES_MX: BonusCategoriesTranslations = {
	title: "Categorías de Bonificación",
	notification: {
		create: "Categoría de bonificación creada exitosamente",
		edit: "Categoría de bonificación editada exitosamente",
		changeStatus: "Categoría de bonificación modificada exitosamente",
		delete: "Categoría de bonificación eliminada exitosamente",
	},
	delete: {
		title: "Eliminar Categoría de Bonificación",
		subtitle: "¿Está seguro que desea eliminar esta categoría de bonificación?",
		confirm: "Sí, eliminar",
		cancel: "Cancelar",
	},
	header: {
		create: "Nueva Categoría de Bonificación",
		edit: "Editar Categoría de Bonificación",
		search: "Buscar...",
		changeStatus: "Cambiar Estado",
		delete: "Eliminar Categoría",
	},
	list: {
		title: "Categorías de bonificación",
		status: {
			label: "Estado",
			active: "Activo",
			inactive: "Inactivo",
		},
		category: "Categoría",
		employees: "Autorizados",
		daysToExpire: "Vencimiento",
		days: "días",
		trash: "Eliminar",
		options: {
			label: "Opciones",
			edit: "Editar Categoría",
			inactive: "Desactivar Categoría",
			active: "Activar Categoría",
		},
	},
	form: {
		name: {
			label: "Nombre de la categoría",
			required: "El nombre es obligatorio",
			placeholder: "Ingrese el nombre de la categoría",
			maxLength: "Máximo 30 caracteres",
		},
		daysToExpire: {
			label: "Validez de la bonificación",
			required: "Campo obligatorio",
			placeholder: "Días",
			positive: "El número de días debe ser positivo",
			maxValue: "Máximo 365 días",
		},
		employees: {
			label: "Empleados autorizados",
			placeholder: "Seleccionar empleados",
			validation: "Seleccione al menos un empleado",
		},
	},
};
