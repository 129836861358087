import { pos } from "./pos";
import { barReports } from "./bar-reports";
import { panel } from "./panel";
import { clients } from "./clients";
import { conference } from "./conference";
import { entranceBilling } from "./entrance-billing";
import { eventReports } from "./event-reports";
import { financialReport } from "./financial-report";
import { zigTags } from "./zig-tags";
import { booking } from "./booking";
import { multivendor } from "./multivendor";
import { sellChip } from "./sell-chip";
import { operationReports } from "./operation-reports";
import { hawkersReport } from "./hawkers-report";
import { tableReports } from "./table-reports";
import { posBar } from "./pos-bar";
import { device } from "./device";
import { USER_BONUS_TABLE_PT_BR } from "#components/tables/reports/user-bonus-table/translations";
import { BONUS_REPORT_AT_EVENT_PT_BR } from "#pages/event/event-reports/bonus-report-at-event/translations";
import { TRANSACTIONAL_HISTORY_TABLE_PT_BR } from "#pages/event/event-client-detail/client-info/transactional-history/transactional-history-table/translations";
import { TRANSACTIONAL_HISTORY_PT_BR } from "#pages/event/event-client-detail/client-info/transactional-history/translations";

export const eventPT = {
	pos,
	posBar,
	device,
	barReports,
	panel,
	clients,
	entranceBilling,
	eventReports,
	financialReport,
	zigTags,
	booking,
	multivendor,
	sellChip,
	conference,
	operationReports,
	hawkersReport,
	tableReports,
	userBonusTable: USER_BONUS_TABLE_PT_BR,
	bonusReportAtEvent: BONUS_REPORT_AT_EVENT_PT_BR,
	transactionalHistory: TRANSACTIONAL_HISTORY_PT_BR,
	transactionalHistoryTable: TRANSACTIONAL_HISTORY_TABLE_PT_BR,
};
