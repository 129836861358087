import { TransactionalHistoryTranslations } from "./transactional-history-pt-br";

export const TRANSACTIONAL_HISTORY_FR_FR: TransactionalHistoryTranslations = {
	sheet: {
		fileName: "historique-transactionnel-de-",
		in: "-en-",
		title: "Historique transactionnel de {{user}} en {{event}}",
		id: "ID",
		operation: "Opération",
		bonusCategoryName: "Catégorie de bonus",
		noCategory: "Sans catégorie",
		employee: "Vendeur",
		value: "Valeur",
		date: "Date",
		hour: "Heure",
	},
};
