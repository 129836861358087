import { importMenu } from "./import-menu";
import { deliverySettings } from "./settings";
import { syncMenu } from "./sync-menu";
import { deliveryMenus } from "./menus";

export const delivery = {
	goBack: "Back",
	updateManyExternalIntegrations: {
		success: "Integrations updated successfully",
	},
	externalStoreIntegrationStatus: {
		synced: "Synced",
		pendingSyncing: "Syncing pending",
		pendingImporting: "Importing pending",
	},
	deliverySettings,
	syncMenu,
	importMenu,
	deliveryMenus,
};
