export const deliveryMenus = {
	header: {
		mainTitle: "Menús",
		mainDescription: "Visualiza, crea y edita menús de Delivery.",
		editDescription: "Visualiza y edita tu menú.",
	},
	menuList: "Menú de delivery",
	status: {
		active: "Activo",
		pendingSync: "Sincronización pendiente",
		pendingImport: "Importación pendiente",
		inactive: "Inactivo",
		synced: "Sincronizado",
	},
	tabs: {
		importedMenus: "Menús importados",
		whiteLabelMenus: "Whitelabel",
	},
	menuCard: {
		message: "Operación 24 horas",
		at: "a las",
		delivery: "Delivery",
		table: "Pedido en mesa",
		businessHour: "Horario de atención",
		type: "Tipo",
		importedAt: "Importado en",
		createdAt: "Creado en",
		products: "Productos",
		numberOfProducts: "{{ number }} Productos",
		unsyncedProducts: "productos no sincronizados",
		notImportedProducts: "productos no importados",
	},
	actions: {
		seeMenu: "Ver menú",
		continueImport: "Continuar con la importación",
		continueSync: "Continuar sincronización",
		create: "Crear nuevo",
		import: "Importar menú",
		search: "Buscar menús",
		manualMode: "Modo manual",
		editMenu: "Editar menú",
		deleteMenu: "Eliminar menú",
		addProduct: "Agregar producto",
		searchProduct: "Buscar producto",
	},
	categories: {
		appetizers: "Entrantes",
		mainCourses: "Platos principales",
		desserts: "Postres",
		nonAlcoholicDrinks: "Bebidas sin alcohol",
		alcoholicDrinks: "Bebidas alcohólicas",
	},
	emptyState: {
		title: "Menú de delivery",
		message: "Parece que aún no has creado ningún menú",
		create: "Crear menú",
		integration: "Importar menú",
	},
};
