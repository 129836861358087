import { TransactionalHistoryTranslations } from "./transactional-history-pt-br";

export const TRANSACTIONAL_HISTORY_ES_MX: TransactionalHistoryTranslations = {
	sheet: {
		fileName: "historial-transaccional-de-",
		in: "-en-",
		title: "Historial transaccional de {{user}} en {{event}}",
		id: "ID",
		operation: "Operación",
		bonusCategoryName: "Categoría de bonificación",
		noCategory: "Sin categoría",
		employee: "Vendedor",
		value: "Valor",
		date: "Fecha",
		hour: "Hora",
	},
};
