import { tableRangeSettings } from "./table-range-settings";

export const bars = {
	search: "Pesquisar",
	searchBarPlaceholder: "Buscar por nome do bar",
	searchProductPlaceholder: "Buscar por produto",
	searchCategoryPlaceholder: "Buscar por categoria de produto",
	manageBar: "Gerenciar Bar",
	menuTooltipActive: "Cardápio vinculado a este bar e ativo",
	menuTooltipInactive: "Cardápio vinculado a este bar e inativo",
	emptyMessage: "Sem itens para mostrar",
	addBar: "Adicionar bar",
	editBar: "Editar bar",
	yesDeleteBar: "Sim, excluir bar",
	deleteMessage:
		"A exclusão impactará a operação: os produtos deixarão de ser exibidos e as impressoras serão desassociadas. Essa ação não pode ser desfeita. Deseja continuar?",
	delete: "Apagar",
	cancel: "Cancelar",
	deleteBar: "Excluir Bar",
	stock: "Estoque:",
	printerIps: "IP da impressora",
	activeProducts: "Produtos Ativos",
	inactiveProducts: "Produtos Inativos",
	notifications: {
		productActivated: "Produto ativado com sucesso",
		productDeactivated: "Produto desativado com sucesso",
	},
	transfer: {
		noItems: "Nenhum item disponível",
		noCategoryFound: 'Nenhuma categoria encontrada para "{{category}}"',
		noProductFound: 'Nenhum produto encontrado para "{{product}}"',
		noResultsFound:
			'Nenhum resultado encontrado para categoria "{{category}}" e produto "{{product}}"',
		selectProductsToActivate: "Selecionar produtos para ativar",
		selectProductsToDeactivate: "Selecionar produtos para desativar",
		inactiveProductTooltip:
			"Esse produto não está ativo no place e não estará disponível para venda no PDV e nem será impresso em pedidos de delivery",
	},
	modal: {
		barName: "Nome do bar",
		barStock: "Estoque",
		addNewIp: "Adicionar IP de impressora (Opcional)",
		printerType: "Tipo",
		printerOffline: "Offline",
		numberOfLanes: "Qtd de vias",
		numberOfLanesDescription: "Quantidade de vias impressas na impressora após o pedido.",
		menu: "Cardápio",
		logoFicha: "Logo da ficha",
		logoFichaInfo:
			"Caso não selecione uma logo para ficha no bar, a logo impressa na ficha será a do {{placeName}}",
		logoFichaDescription: "Imagem que aparecerá impressa na ficha.",
		stockDescription: "O estoque selecionado será utilizado por este bar.",
		menuDescription: "O cardápio selecionado será utilizado por este bar.",
		barNamePlaceholder: "Digite o nome do bar",
		barStockPlaceholder: "Selecione",
		menuPlaceholder: "Selecione um ou mais cardápios",
		saveModifiedBar: "Salvar alterações",
		addBar: "Adicionar bar",
		errors: {
			printerTypeRequired: "O campo tipo de impressora é obrigatório",
			printerNameRequired: "Campo nome obrigatório",
			printerStockRequired: "Campo estoque obrigatório",
			printerIpFormat: "O campo de IP deve ser válido ex: 192.168.1.1",
			pagesToPrintMin: "O campo Nº de vias deve ser de no mínimo {{min}}",
			pagesToPrintMax: "O campo Nº de vias deve ser no máximo {{max}}",
			validIp: "O campo de ip deve ser válido",
			requiredField: "Campo obrigatório",
			maxLength: "O campo deve ter no máximo {{max}} caracteres",
		},
		addImage: "Adicionar Imagem",
		fileTypeInfo: "Tipo de arquivo: jpeg, pjpeg, png",
	},
	tableRangeSettings,
};
