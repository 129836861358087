export const syncMenu = {
	title: "Delivery Menu ({{externalStoreType}})",
	description:
		"Select the product from Zig's list to be associated with the item from {{externalStoreType}}. The items will become unique.",
	menuNameByExternalType: "Delivery Menu ({{externalStoreType}})",
	transferProducts: {
		warning:
			"{{amount}} products were not synced. Would you like to import them into the Delivery menu?",
		description: "Select the items you would like to import, or finish to proceed.",
		amountItemsSelected: "<strong>{{amount}}</strong> items selected",
		completeBtn: "Finish without importing",
		importItemsBtn: "Import items",
	},
	bindProduct: {
		searchPlaceholder: "Search for another product to sync",
		allProducts: "All products",
		notProductFound: "No product found",
		errorProductFound: "An error occurred while searching for products",
		similarity: {
			title: "No similar products found",
			description:
				"Search in the search bar above, or skip to the next product.<br/>You will be able to import the products without similarity at the end of the synchronization.",
		},
		saveLater: "Save and continue later",
		undo: "Undo previous action",
		completeSync: "Complete synchronization",
		skuInfo: {
			title: "The SKU of this product will be replaced",
			description:
				"The SKU from ZIG (selected product on the side) will overwrite both menus. This will allow centralized management.",
		},
		undoModal: {
			title: "Undo synchronization?",
			description:
				"When you confirm, we will go back to the previous product and the synchronization will be undone.",
			okText: "Undo",
			cancelText: "Cancel",
		},
	},
	productCard: {
		descriptionLabel: "Description",
		active: "Active",
		inactive: "Inactive",
	},
	transferProductSuccess: {
		title: "Integration complete",
		description:
			"Your integration is complete, and your products have been successfully synced!",
		button: "View products and menus",
	},
	syncMenuSuccess: {
		title: "Integration complete",
		description:
			"Your integration is complete, and your products have been successfully synced!",
		button: "View products and menus",
		continue: "View unimported products",
	},
	errorContent: {
		title: "Unable to import products",
		description:
			"Something unexpected happened, and we couldn't complete the product import. Complete your integration and finish your menu later.",
		completeBtn: "Complete sync without importing",
		retryBtn: "Try to import again",
	},
	onboarding: {
		step1: {
			title: "We will display one product at a time",
			description:
				"Each imported product will be displayed alongside equivalent suggestions in the ZIG product catalog. You'll select the ideal product to unify.",
		},
		step2: {
			title: "A single SKU",
			description:
				"When selecting the product, the SKU from ZIG will prevail and you'll be able to manage it centrally in our dashboard.",
		},
		step3: {
			title: "Import what's not yet on Zig",
			description:
				"When you've finished synchronizing the equivalent items, you'll be able to import the products that weren't identified in the ZIG catalog. This way, you'll have all your products in one place!",
		},
		skipBtn: "Skip",
		submitBtn: "Start synchronization",
	},
};
