import { contentsSection } from "./contents-section";

export const productDetail = {
	isActive: "Active product",
	isInactive: "Inactive product",
	variable: "Variable",
	buttonEditProduct: "Edit product",
	modalDeleteProduct: {
		titleModal: "Confirmation required",
		confirmDeleteProduct: "Do you want to delete the product: {{productName}}",
	},
	sectionsCards: {
		productDetail: "Product Details",
		fiscalData: "Tax data",
		cardProductSoldBy: "Product sold by g/ml",
		cardProductMountable: "Product mountable",
		releaseValue: "Release value",
		conversionRule: "Conversion rule",
	},
	duplicateProductMountable: "Duplicate buildable product",
	contentsSection,
};
