import classNames from "classnames";
import * as React from "react";
import s from "./wrapper.scss";
import SvgInfo from "#components/icon/imgs/info.svg";
import { Tooltip } from "#components/primitive";
import { TooltipPlacement } from "antd/lib/tooltip";

export type WrapperValidateType = "error" | "warning" | "help";

export interface IWrapperValidate {
	type: WrapperValidateType;
	value: string;
}

export interface IWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
	inputName?: string;
	label?: React.ReactNode;
	labelProps?: React.HTMLAttributes<HTMLLabelElement>;
	tooltip?: React.ReactNode;
	validate?: IWrapperValidate;
	validateClassName?: string;
	validateProps?: React.HTMLAttributes<HTMLSpanElement>;
	childrenContainerClassName?: string;
	description?: string;
	descriptionClassName?: string;
	required?: boolean;
	placementTooltip?: TooltipPlacement;
}

export function Wrapper({
	children,
	className,
	inputName,
	label,
	labelProps,
	tooltip,
	validate,
	validateClassName,
	validateProps,
	childrenContainerClassName,
	description,
	descriptionClassName,
	required,
	placementTooltip,
	...props
}: IWrapperProps) {
	return (
		<div className={classNames(s.container, className)} {...props}>
			{(label || tooltip) && (
				<div className={s.headerContainer}>
					<>
						{label && (
							<label className={s.label} htmlFor={inputName} {...labelProps}>
								{required && <span className={s.required}>*</span>}
								<span>{label}</span>
							</label>
						)}
						{tooltip && (
							<Tooltip placement={placementTooltip} content={tooltip}>
								<div>
									<SvgInfo />
								</div>
							</Tooltip>
						)}
					</>
				</div>
			)}
			<div className={childrenContainerClassName}>{children}</div>
			{validate && (
				<span
					className={classNames(s.validate, validateClassName, {
						[s.warning]: validate.type === "warning",
						[s.error]: validate.type === "error",
						[s.help]: validate.type === "help",
					})}
					{...validateProps}
				>
					{validate.value}
				</span>
			)}
			{description && (
				<span className={classNames(s.description, descriptionClassName)}>
					{description}
				</span>
			)}
		</div>
	);
}
