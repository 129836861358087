import { BonusReportTranslations } from ".";

export const BONUS_REPORT_FR_FR: BonusReportTranslations = {
	title: "Bonus",
	description: "Voir tous les rapports de concession et d'utilisation des bonus",
	tabs: {
		given: "Bonus Accordé",
		used: "Bonus Utilisé",
	},
};
