export const details = {
	goBack: "VOLTAR",
	edit: "Editar",
	confirm: "Tem certeza que deseja alterar as informações deste cliente?",
	clientDetails: "Dados de cliente",
	consummationGiven: "Consumação dada",
	rechargesReturned: "Recargas devolvidas",
	singleProductReturns: "Devolução de produtos",
	paymentMethods: {
		app: "Aplicativo",
		cash: "Dinheiro",
		creditCard: "Crédito",
		debitCard: "Débito",
		zigPosCredit: "Zig pós crédito",
		zigPosDebit: "Zig pós débito",
		voucher: "Voucher",
		dashboard: "Dashboard",
		anticipated: "Antecipado",
		postEvent: "Após o evento",
		ame: "Ame",
		deliveryOnline: "Delivery online",
		ifood: "Ifood",
		uber: "Uber",
		rappi: "Rappi",
		total: "Total",
		pix: "Pix",
		installment: "Parcelamento",
		foodVoucher: "Vale-refeição",
		noMethod: "Não informado",
		online: "Online",
		mbway: "Mbway",
	},
	data: {
		cpf: "CPF",
		email: "Email",
		noEmail: "Sem email cadastrado",
		phone: "Telefone",
		noPhone: "Sem telefone cadastrado",
		birthDate: "Nascimento",
		notRegistered: "Data não cadastrada",
		remaningValue: "Saldo disponível no local",
		hasApp: "Possui Aplicativo",
		checkIn: "Entrada",
		checkOut: "Saída",
		noRegistry: "Sem registro",
		notDefined: "Não ocorreu",
		at: "às",
		showMore: "ver mais informações",
		showLess: "ver menos",
		clientsAtTag: "Quantidade de clientes na mesa",
	},
	tabs: {
		bonusAndDiscounts: "Bônus e Descontos",
		debtorsReport: "Contas em aberto",
		ticketsByUserReport: "Controle de fichas",
	},
	sheet: {
		fileName: "historico-consolidado",
		withoutInfo: "Sem informações para exibir",
		summary: {
			title: "Resumo",
			recharges: "Recargas",
			rechargesFromOtherEvents: "Recargas de outros eventos",
			notSyncRecharges: "Recargas não sincronizadas",
			notUsedRecharges: "Recargas não utilizadas",
			rechargeDevolutions: "Devolução de Recargas",
			rechargeBalance: "Saldo de recargas",
			bonusReceveid: "Bônus Recebido",
			entrances: "Entradas",
			products: "Produtos",
			service: "Serviço",
			totalConsumption: "Consumo Total",
			payments: "Pagamentos",
			paymentsInOtherEvents: "Pagamentos em outros eventos",
			openedBill: "Contas em aberto",
			minimumConsumptionUsed: "Consumação utilizada",
			notUsedMinimumConsumption: "Consumação não utilizada",
			columns: {
				method: "Tipo",
				value: "Valor",
			},
			bonusPayments: "Pagamento com bônus remanescente",
		},
		consumption: {
			title: "Consumo",
			columns: {
				name: "Nome",
				count: "Quantidade",
				value: "Valor",
			},
		},
		recharges: {
			title: "Recargas",
			columns: {
				method: "Método",
				value: "Valor",
			},
		},
		payments: {
			title: "Pagamentos",
			columns: {
				method: "Tipo",
				value: "Valor",
			},
		},
		actions: {
			title: "Ações",
			columns: {
				method: "Tipo",
				value: "Valor",
			},
		},
		transactionalHistory: {
			columns: {
				transaction: "transação",
				seller: "vendedor",
				value: "valor",
				id: "id",
				time: "horário da transação",
				sync: "sincronização",
				zigCode: "zigTag",
				bonusCategoryName: "categoria de bônus",
			},
		},
		consumptionHistory: {
			title: "Histórico de Consumo",
			consumption: "Consumo",
			servicePaidOnEvent: "Serviço pago no evento",
			amountConsumption: "Consumo total",
			columns: {
				date: "Data",
				type: "Tipo",
				item: "Item",
				qtd: "Qtd",
				value: "Valor",
				discountValue: "Desconto",
			},
		},
	},
	downloadTable: {
		fileName: "historico-consolidado",
		recharges: {
			title: "Recargas",
			columns: {
				date: "Data",
				rechargesInEvent: "Recargas no evento",
				rechargesFromOtherEvents: "Recargas de outros eventos",
				notSynchRecharges: "Recargas não sincronizadas",
				notUsedRecharges: "Recargas não utilizadas",
				rechargeDevolutions: "Devolução de Recargas",
			},
		},
		consumption: {
			title: "Consumo",
			columns: {
				date: "Data",
				entrances: "Entradas",
				products: "Produtos",
				service: "Serviço",
				totalConsumption: "Consumo Total",
			},
		},
		payments: {
			title: "Pagamentos",
			columns: {
				date: "Data",
				paymentsInEvent: "Pagamentos no evento",
				paymentsInOtherEvents: "Pagamentos de outros eventos",
				openedBill: "Contas em aberto",
				bonusPayments: "Pagamento com bônus remanescente",
			},
		},
		bonusAndConsumption: {
			title: "Consumação e Bônus",
			columns: {
				date: "Data",
				bonusReceived: "Bônus recebido",
				consumptionUsed: "Consumação usada",
				notUsedConsumption: "Consumação não usada",
			},
		},
		actions: {
			title: "Ações",
			columns: {
				date: "Data",
				removedTips: "Gorjetas removidas",
				postPaidLimitChanged: "Mudança no limite pós-pago",
				attachCount: "Cartões zig anexados",
				packages: "Pacotes",
				productRefunds: "Produtos estornados",
				promotionsUsed: "Promoções usadas",
			},
		},
	},
	consolidatedHistory: {
		label: "Histórico Consolidado",
		printInfo: "Imprimir Informações",
		recharges: "Recargas",
		totalValue: "Valor",
		consumption: "Consumo",
		bonusAndConsumption: "Consumação/Bônus",
		payments: "Pagamentos",
		action: "Ação",
		quantity: "Quantidade",
		method: "Método",
		unknown: "Desconhecido",
		balance: "Saldo",
		consumptionObligations: "Valor de consumação mínima",
		consumptionObligationsNotUsed: "Sobra",
		product: "Produto",
		qntt: "Qntd.",
		value: "Valor",
		discount: "Desconto",
		isShared: "-(compartilhado)",
		totalConsumption: "Consumo total",
		others: "Outros",
		name: "Nome",
		formatters: {
			rechargesFromOtherEvents: "Recargas de outros eventos",
			rechargeDevolutions: "Recargas devolvidas",
			rechargeDevolutionsFromOtherEvents: "Recargas devolvidas de outros eventos",
			notSyncRecharges: "Recargas não sincronizadas",
			bonusReceived: "Bônus recebido",
			minimumConsumptionUsed: "Consumação utilizada",
			notUsedMinimumConsumption: "Consumação não utilizada",
			tip: "Serviço",
			openedBillPayments: "Pagamento de contas em aberto",
			paymentsInEvent: "Pagamentos no evento",
			paymentsInOtherEvents: "Pagamentos em outros eventos",
			openedBill: "Contas em aberto",
			notUsedRecharges: "Recargas não utilizadas",
			rechargesInEvent: "Recargas no evento",
			entrances: "Entradas",
			products: "Produtos",
			consumptionObligations: "Consumações mínimas",
			consumptionObligationsNotUsed: "Sobra de consumações mínimas",
			productRefunds: "Produtos estornados",
			packages: "Pacotes",
			promotionsUsed: "Promoções usadas",
			removedTip: "Gorjetas removidas",
			postPaidLimitChanged: "Mudança no limite do pós-pago",
			attachCount: "Cartões Zig anexados",
			bonusPayments: "Pagamento com bônus remanescente",
		},
	},
	transactionalHistory: {
		label: "Histórico Transacional",
		export: "Exportar para Excel",
		formatters: {
			attach: "Cadastro de cartão",
			attachToReserve: "Inclusão no pacote",
			bonus: "Bônus",
			changePostLimit: "Mudança de limite",
			consumeRecharge: "Devolução de recarga",
			detachAndRefundActivation: "Devolução de cartão",
			discount: "Desconto",
			entranceTransaction: "Entrada",
			minimumConsumption: "Consumação",
			postPayment: "Pagamento",
			productTransaction: "Compra",
			promotion: "Ganhou promoção",
			recharge: "Recarga",
			refundRecharge: "Estorno de recarga",
			refundTransaction: "Estorno",
			sharedTransaction: "Pagamento compartilhado",
			tipRemoval: "Serviço removido",
			appPrePayment: "Pré-pagamento via app",
			consumptionObligation: "Consumação Mínima",
			zigDeviceSyncForce: "Sincronização forçada de saldo",
			transferProductTransaction: "Transferência de produto",
			zigCardPixRefund: "Reembolso de pix",
			transferConsumptionObligation: "Transferência de consumação obrigatória",
		},
		employee: "Vendedor",
		value: "Valor",
		id: "ID",
		hour: "Horário",
		sheet: {
			fileName: "historico-transacional-de-",
			in: "-em-",
			title: "Histórico transacional de {{user}} em {{event}}",
			id: "ID",
			operation: "Operação",
			employee: "Vendedor",
			value: "Valor",
			date: "Data",
			hour: "Horário",
			bonusCategoryName: "Categoria de bônus",
		},
		event: "Evento",
		paymentTable: {
			method: "Método",
			value: "Valor",
			name: "Pagantes",
			cpf: "CPF",
			acquirer: "Adquirente",
		},
		productTable: {
			name: "Produto",
			details: "Detalhes",
			value: "Preço",
			count: "Qntd.",
			discountValue: "Desconto",
			totalValue: "Valor final",
			total: "TOTAL",
			transferFrom: "Transferido de ",
			transferTo: "Transferido para ",
			item: "Item",
			section: "Etapa",
		},
		refundTable: {
			name: "Produto",
			count: "Qntd.",
		},
		service: "Serviço",
		unknown: "Desconhecido",
		loading: "Carregando...",
		acquirerModal: {
			unknown: "Desconhecido",
			acquirer: "Adquirente",
			urlLabel: "url",
			cardHolderNumber: "Cartão",
			cardHolderName: "Nome do titular",
			transactionType: "Tipo de transação",
			notKnown: "Não conhecido",
			paidAmount: "Valor transacionado",
		},
	},
	fiscalNotes: {
		label: "Notas Fiscais",
		send: "Enviar notas fiscais por Email",
		emit: "Emitir nota fiscal",
		mode: {
			serviceNFS: "Serviço (NFS-e)",
			consumptionNFC: "Consumo (NFC-e)",
			consumptionSAT: "Consumo (SAT)",
		},
		columns: {
			time: "Data/Hora",
			type: "Tipo",
			status: "Status",
			links: "Links",
		},
		invoiceStatus: {
			canceled: "Nota cancelada",
			issued: "Nota emitida",
			issuing: "Emitindo nota",
			error: "Erro ao emitir",
		},
		showError: "Visualizar erro",
		image: "Imagem",
		generate: "Gerar {{type}}",
		at: "às",
		empty: "Sem notas fiscais do cliente para o período selecionado.",
		modalError: {
			title: "Descrição do erro",
			content: "Entre em contato com o suporte e envie este erro",
			error: "Erro",
		},
		cancelInvoice: {
			title: "Tem certeza que deseja cancelar a nota fiscal?",
			okText: "Sim, cancelar nota fiscal",
		},
	},
	ticketByUserReport: {
		excel: {
			title: "Controle de fichas - {{userName}}",
			filename: "controle_de_fichas_{{userName}}",
		},
		columns: {
			product: "Produto",
			burnedTickets: "Fichas queimadas",
			refundedTickets: "Fichas estornadas",
			purchasedTickets: "Fichas compradas",
		},
		emptyState: {
			title: "Nenhuma ficha consumida",
			description: "Usuário ainda não comprou nenhuma ficha",
		},
	},
	bonusAndDiscounts: {
		discounts: {
			label: "Descontos",
			columns: {
				date: "Data",
				givenBy: "Dado por",
				reason: "Justificativa",
				percentage: "Porcentagem",
				value: "Desconto",
			},
		},
		bonus: {
			label: "Bônus",
			columns: {
				date: "Data",
				givenBy: "Dado por",
				reason: "Justificativa",
				totalValue: "Valor recebido",
				usedValue: "Valor gasto",
			},
		},
		sheet: {
			fileName: "bonus-e-descontos",
			discount: {
				clientTitle: "Descontos de {{client}}",
				title: "Descontos",
				columns: {
					date: "Data",
					givenBy: "Dado por",
					reason: "Justificativa",
					percentage: "Porcentagem",
					value: "Desconto",
				},
			},
			bonus: {
				clientTitle: "Bônus de {{client}}",
				title: "Bônus",
				columns: {
					date: "Data",
					givenBy: "Dado por",
					reason: "Justificativa",
					totalValue: "Valor recebido",
					usedValue: "Valor gasto",
				},
			},
		},
	},
	issueNotes: "Emissão de notas fiscais",
	invoices: {
		alert:
			"Caso deseje agrupar as notas fiscais de outros clientes às do já selecionado, basta adicioná-los abaixo",
		cpfPlaceholder: "Insira um CPF",
		nifPlaceholder: "Insira um NIF",
		client: "Cliente",
		valueToEmit: "Valor a emitir",
		totalNotissued: "Total não emitido",
		otherCPF: "Emitir para outro CPF",
		otherNIF: "Emitir para outro NIF",
		otherCNPJ: "Emitir para outro CNPJ",
		noCPForCNPJ: "Emitir sem CPF e sem CNPJ",
		noNIF: "Emitir sem NIF",
		issueTo: "Emitir para",
		cpf: "CPF",
		nif: "NIF",
		cnpj: "CNPJ",
		cnpjPlaceholder: "Insira um CNPJ",
		empty: "Não existem transações para serem emitidas.",
		products: "Produtos",
		count: "Quantidade",
		discountValue: "Desconto",
		value: "Valor",
		id: "Transação",
		date: "Hora e Data",
		unitValue: "Valor Total",
		steps: {
			buyers: "Compradores",
			transactions: "Transações não emitidas",
			consumption: "Consumação Mínima não emitida",
			emission: "Emissão",
		},
	},
	printable: {
		bonus: "Bônus",
		recharge: "Recarga",
		date: "Data",
		author: "Autor",
		total: "Total",
		expired: "Expirada",
		used: "Utilizado",
		method: "Método",
		refunded: "Estornado",
		purchase: "Compra",
		shared: "Compartilhada",
		service: "Serviço",
		products: "Produtos",
		name: "Nome",
		count: "Quantidade",
		discount: "Desconto",
		totalProducts: "Total ({{count}} Produtos)",
		paidBill: "Conta paga",
		cashier: "Caixa",
		payments: "Pagamentos",
		paymentMethod: "Método de pagamento",
		payers: "Pagantes",
		value: "Valor",
		cpf: "cpf",
		email: "Email",
		phone: "Telefone",
		zigcode: "Zigcode",
		unavailable: "Indisponível",
	},
	sendByEmail: {
		nfce: "Consumo (NFC-e)",
		nfse: "Serviço (NFS-e)",
		sat: "Consumo (SAT)",
		sendEmail: "Enviar email",
		datetime: "Data/Hora",
		type: "Tipo",
		sendInvoucesByEmail: "Enviar notas fiscais por email",
		invoices: "Notas fiscais",
		email: "Email",
	},
	checkOutMethod: {
		biometry: "Biometria",
		pdv: "PDV",
		app: "App",
		menu: "Cardápio online",
		automatic: "Automático",
	},
};
