export const importMenu = {
	title: "Configurar Delivery e Integrações",
	description: "Integre com um Delivery parceiro e centralize sua gestão na Zig.",
	info:
		"Alguns produtos podem ter itens complementares e opcionais. Você poderá revisar mais detalhes e fazer ajustes em “Produtos e Cardápios” após a importação.",
	selectAllCategories: "Selecionar todas as categorias",
	searchCategory: "Buscar categoria",
	amountItemsSelected: "<strong>{{amount}}</strong> itens selecionados",
	cancelBtn: "Cancelar",
	importItensSelected: "Importar itens selecionados",
	notProductFound: "Nenhum produto encontrado",
	returnToMenu: "Voltar para o cardápio",
	menuNameByExternalType: "Cardápio de Delivery ({{externalType}})",
	importMenuError: {
		title: "Não foi possível importar seu cardápio",
		description:
			"Algo inesperado aconteceu e não conseguimos concluir a importação. Conclua sua integração e finalize seu cardápio mais tarde.",
		completeWithoutMenuBtn: "Concluir integração sem cardápio",
		retryBtn: "Tentar importar novamente",
	},
	importMenuSuccess: {
		title: "Seu cardápio foi importado com sucesso",
		description:
			"Você pode gerenciar seus cardápios na aba “Cardápios” desse mesmo módulo, além de gerenciar seus produtos no módulo “Produtos e Cardápios”.",
		viewProductsBtn: "Ver produtos",
	},
	integrationSuccess: {
		title: "Integração concluída",
		description:
			"Sua integração foi concluída com sucesso! Você pode gerenciar seus cardápios mais tarde no módulo “Produtos e Cardápios”.",
		viewProductsAndMenusBtn: "Ver produtos e cardápios",
	},
	loadingMessage: "Aguarde enquanto identificamos os itens do seu cardápio",
	loadingMutation: "Aguarde enquanto concluímos a integração",
	active: "Ativo",
	inactive: "Inativo",
};
