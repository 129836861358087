export const tableRangeSettings = {
	drawer: {
		title: "Table Range",
	},
	header: {
		filter: {
			optionAllLabel: "Show all",
			label: "Filter by bar",
			placeholder: "Select or search a bar",
		},
		addBtn: "Add table range",
	},
	card: {
		title: "Tables {{from}} to {{to}}",
		barsLabel: "Print on bars",
	},
	form: {
		status: {
			errors: {
				description:
					"It was not possible to add the range. Check if there is not a table number already used on another range.",
				title: "Something went wrong.",
			},
			success: {
				titleEditing: "Updated successfully.",
				titleCreating: "Table ranges created successfully!",
			},
		},
		generalWrapperLabel: "Range {{number}}",
		intervalWrapperLabel: "Table range",
		bars: {
			label: "Bars that will print the orders of the tables above",
			placeholder: "Search by name",
		},
		removeIntervalBtn: "Remove range",
		editingWarningTooltip: "You can't add new ranges in edit mode.",
		addIntervalBtn: "Add another range",
		errors: {
			atLeastOneBar:
				"You must select at least one bar to print the orders of the tables above. (range {{number}})",
			missingFromTable:
				"Type the number of the first table of the range (range {{number}})",
			missingToTable: "Type the number of the last table of the range (range {{number}})",
			fromTableAlreadyUsed:
				"The first table of the range (range {{number}}) is already used on another range.",
			toTableAlreadyUsed:
				"The last table of the range (range {{number}}) is already used on another range.",
		},
	},
	deleteModal: {
		title: "Delete table range",
		message:
			"Are you sure you want to delete the table range {{from}} to {{to}}? This action cannot be undone.",
	},
	tabs: {
		generalSettings: "General Settings",
		printByTableSettings: "Configure Table Printing",
	},
};
