import { TransactionalHistoryTableTranslations } from "./transactional-history-table-pt-br";

export const TRANSACTIONAL_HISTORY_TABLE_ES_MX: TransactionalHistoryTableTranslations = {
	columns: {
		transaction: "Transacción",
		bonusCategoryName: "Categoría de bonificación",
		seller: "Vendedor",
		zigCode: "ZigTag",
		value: "Valor",
		id: "ID",
		time: "Hora de la transacción",
		sync: "Sincronización",
	},
	noCategory: "Sin categoría",
	withoutInfo: "Sin información",
	noCard: "Sin tarjeta",
};
