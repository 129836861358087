export const TRANSACTIONAL_HISTORY_PT_BR = {
	sheet: {
		fileName: "historico-transacional-de-",
		in: "-em-",
		title: "Histórico transacional de {{user}} em {{event}}",
		id: "ID",
		operation: "Operação",
		bonusCategoryName: "Categoria de bônus",
		noCategory: "Sem categoria",
		employee: "Vendedor",
		value: "Valor",
		date: "Data",
		hour: "Horário",
	},
};

export type TransactionalHistoryTranslations = typeof TRANSACTIONAL_HISTORY_PT_BR;
