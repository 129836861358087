import { EnterpriseApiSignature, EnterpriseEndpointKey } from "#resources/helpers/api";
import {
	InvalidateOptions,
	InvalidateQueryFilters,
	useQueryClient,
} from "@tanstack/react-query";

interface Props<Keys extends EnterpriseEndpointKey> {
	// A chave de endpoint para a query.
	endpoint: Keys;
	// Parâmetros opcionais para a consulta.
	params?: Partial<EnterpriseApiSignature<Keys>["args"]>;
}

/**
 * Hook para invalidar uma query.
 *
 * @template K - O tipo de EnterpriseEndpointKey.
 * @param {Props<K>} props - As props para o hook.
 * @returns {Function} - Uma função que pode ser usada para invalidar uma consulta.
 * @example
 * ```ts
 *const invalidateGetPlaceProductsQuery = useInvalidateQuery({
 *  endpoint: "getPlaceProducts",
 *  params: { placeId: "123" }
 *});
 *
 *invalidateGetPlaceProductsQuery()
 *
 * // Usando com parâmetros dinâmicos
 *const invalidateGetPlaceProductsQuery = useInvalidateQuery({
 *  endpoint: "getPlaceProducts"
 *});
 *
 *invalidateGetPlaceProductsQuery({ placeId: "456" })
 * ```
 */
export function useInvalidateQuery<K extends EnterpriseEndpointKey>(props: Props<K>) {
	const queryClient = useQueryClient();

	// um wrapper para queryClient.invalidateQueries
	return (
		dynamicParams?: Partial<EnterpriseApiSignature<K>["args"]>,
		filters?: Omit<InvalidateQueryFilters, "queryKey">,
		options?: InvalidateOptions,
	) => {
		// Combine static params with dynamic params if provided
		const combinedParams = dynamicParams
			? { ...props.params, ...dynamicParams }
			: props.params;

		return queryClient.invalidateQueries(
			{ queryKey: [props.endpoint, combinedParams].filter(Boolean), ...filters },
			options,
		);
	};
}
