import { BonusReportTranslations } from ".";

export const BONUS_REPORT_ES_MX: BonusReportTranslations = {
	title: "Bonos",
	description: "Ver todos los informes de concesión y uso de bonos",
	tabs: {
		given: "Bonos Otorgados",
		used: "Bonos Utilizados",
	},
};
