import { UsedBonusReportTranslations } from ".";

export const USED_BONUS_REPORT_ES_MX: UsedBonusReportTranslations = {
	usedBonus: "Bonos Utilizados",
	columns: {
		client: "Empleado Responsable",
		dateTime: "Fecha y Hora",
		reason: "Razón",
		spentValueInBonus: "Valor del Pago en Bonos",
		total: "Total",
	},
};
