import { TransactionalHistoryTableTranslations } from "./transactional-history-table-pt-br";

export const TRANSACTIONAL_HISTORY_TABLE_FR_FR: TransactionalHistoryTableTranslations = {
	columns: {
		transaction: "Transaction",
		bonusCategoryName: "Catégorie de bonus",
		seller: "Vendeur",
		zigCode: "ZigTag",
		value: "Valeur",
		id: "ID",
		time: "Heure de la transaction",
		sync: "Synchronisation",
	},
	noCategory: "Sans catégorie",
	withoutInfo: "Sans information",
	noCard: "Sans carte",
};
