export const USED_BONUS_REPORT_PT_BR = {
	usedBonus: "Bonus Utilizados",
	columns: {
		client: "Funcionário Responsável",
		dateTime: "Data e Hora",
		reason: "Motivo",
		spentValueInBonus: "Valor do Pagamento em Bônus",
		total: "Total",
	},
};

export type UsedBonusReportTranslations = typeof USED_BONUS_REPORT_PT_BR;
