// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/index.js??ruleSet[1].rules[5].use[1]!../../../node_modules/.pnpm/sass-loader@7.3.1_webpack@5.91.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[5].use[2]!./modal-onboarding.module.scss");
if(typeof content === 'string') content = [[module.id, content, '']];
// Prepare cssTransformation
var transform;

var options = {}
options.transform = transform
// add the styles to the DOM
var update = require("!../../../node_modules/.pnpm/style-loader@0.18.2/node_modules/style-loader/lib/addStyles.js")(content, options);
if(content.locals) module.exports = content.locals;
// Hot Module Replacement
if(module.hot) {
	// When the styles change, update the <style> tags
	if(!content.locals) {
		module.hot.accept("!!../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/index.js??ruleSet[1].rules[5].use[1]!../../../node_modules/.pnpm/sass-loader@7.3.1_webpack@5.91.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[5].use[2]!./modal-onboarding.module.scss", function() {
			var newContent = require("!!../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/index.js??ruleSet[1].rules[5].use[1]!../../../node_modules/.pnpm/sass-loader@7.3.1_webpack@5.91.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[5].use[2]!./modal-onboarding.module.scss");
			if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
			update(newContent);
		});
	}
	// When the module is disposed, remove the <style> tags
	module.hot.dispose(function() { update(); });
}