import { BonusCategoriesTranslations } from "./bonus-categories-pt-br";

export const BONUS_CATEGORIES_EN_US: BonusCategoriesTranslations = {
	title: "Bonus Categories",
	notification: {
		create: "Bonus category successfully created",
		edit: "Bonus category successfully edited",
		changeStatus: "Bonus category successfully changed",
		delete: "Bonus category successfully deleted",
	},
	delete: {
		title: "Delete Bonus Category",
		subtitle: "Are you sure you want to delete this bonus category?",
		confirm: "Yes, delete",
		cancel: "Cancel",
	},
	header: {
		create: "New Bonus Category",
		edit: "Edit Bonus Category",
		search: "Search for...",
		changeStatus: "Change Status",
		delete: "Delete Bonus Category",
	},
	list: {
		title: "Bonus Categories",
		status: {
			label: "Status",
			active: "Active",
			inactive: "Inactive",
		},
		category: "Category",
		employees: "Authorized",
		daysToExpire: "Expiration",
		days: "days",
		trash: "Delete",
		options: {
			label: "Options",
			edit: "Edit Category",
			inactive: "Deactivate Category",
			active: "Activate Category",
		},
	},
	form: {
		name: {
			label: "Category name",
			required: "Name is required",
			placeholder: "Enter category name",
			maxLength: "Maximum of 30 characters",
		},
		daysToExpire: {
			label: "Bonus validity",
			required: "Required field",
			placeholder: "Days",
			positive: "Days to expire must be a positive number",
			maxValue: "Maximum is 365 days",
		},
		employees: {
			label: "Authorized employees",
			placeholder: "Select Employees",
			validation: "Select at least one employee",
		},
	},
};
