export const kds = {
	emptyKdsDescription:
		"O KDS ainda não foi criado. Quando disponível, será exibido aqui.",
	editKds: "As suas alterações foram salvas.",
	title: "Configurações do KDS",
	subtitle: "Configure o KDS para aprimorar e organizar as operações da sua cozinha.",
	createdAt: "Criado em",
	seeKds: "Visualizar KDS",
	linkedBar: "Bar vinculado",
	basic: "Básico",
	main: "Principal",
	general: "Geral",
	mainTooltip:
		"O KDS Principal centraliza o controle de pedidos, permitindo alterar status, cancelar, visualizar cancelamentos e acompanhar todos os pedidos.",
	basicTooltip:
		"O KDS Básico mostra todos os pedidos direcionados a ele com status de aberto e concluído, sem permitir alterações ou visualização de cancelamentos.",
	confirmModal: {
		title: "Você tem alterações não salvas",
		description:
			"Para sair da página sem perder o progresso, salve suas alterações. Se continuar	sem salvar, todas as alterações serão perdidas.",
		close: "Sair sem fechar",
	},
	form: {
		name: "Nome do KDS",
		linkedBar: "Bar vinculado",
		status: "Troca de status",
		timeManagement: "Gestão de tempo",
		readyTimeOrderLabel: "Tempo por pedido ser considerado pronto",
		standardTimeOrderLabel: "Tempo padrão por pedido (min.)",
		originOrder: "Origem do pedido (Delivery e outras integrações)",
		orderNumber: "Número do pedido",
		customerName: "Nome do cliente",
		saveChanges: "Salvar alterações",
		generalSettings: "Configurações gerais",
		timeSettings: "Configurações de tempo",
		orderCardSettings: "Configurações do card de pedido",
	},
	statusChangeTypes: {
		auto: "Automaticamente",
		manual: "Manualmente",
	},
	timeManagementTypes: {
		byProduct: "Utilizar tempo por produto da integração",
		defaultTime: "Utilizar um padrão por pedido",
		noManagement: "Não utilizar tempo",
	},
	reports: {
		title: "Relatório",
		description: "Acompanhe o desempenho geral de produção do(s) seu(s) KDS.",
		averageAssemblyOrder: "Tempo médio de montagem dos pedidos",
		productionBySector: "Setor com maior tempo de produção",
		top10FastestProducts: "Top 10 produtos mais rápidos",
		slowestProducts: "Produtos com maior tempo de produção",
		top10LongestOrders: "Top 10 pedidos que mais demoraram",
		order: "Pedido",
		averageProductionTime: "Média do tempo de produção de pedidos",
		highestSectorProduction: "Setor com maior tempo de produção",
		orderTable: {
			orderNumber: "nº do pedido",
			customer: "Cliente",
			origin: "Origem",
			local: "Local",
			date: "Data/hora",
		},
		resumeDrawer: {
			order: "Pedido",
			title: "Resumo do pedido",
			date: "Data e hora",
			customer: "Cliente",
			originOrder: "Origem do pedido",
			locationOrder: "Local do pedido",
			table: {
				quantity: "Qtd",
				product: "Produto",
				sector: "Setor",
				time: "Tempo",
			},
		},
		noItemFound: "Nenhum item encontrado",
	},
};
