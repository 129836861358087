export const deliveryMenus = {
	header: {
		mainTitle: "Menus",
		mainDescription: "View, create, and edit Delivery menus.",
		editDescription: "View and edit your menu.",
	},
	menuList: "Delivery Menu",
	status: {
		active: "Active",
		pendingSync: "Pending Sync",
		pendingImport: "Pending Import",
		inactive: "Inactive",
		synced: "Synced",
	},
	tabs: {
		importedMenus: "Imported Menus",
		whiteLabelMenus: "Whitelabel",
	},
	menuCard: {
		message: "24h operation",
		at: "at",
		delivery: "Delivery",
		table: "Table Order",
		businessHour: "Business Hours",
		type: "Type",
		importedAt: "Imported on",
		createdAt: "Created on",
		products: "Products",
		numberOfProducts: "{{ number }} Products",
		unsyncedProducts: "unsynced products",
		notImportedProducts: "not imported products",
	},
	actions: {
		seeMenu: "View Menu",
		continueImport: "Continue Importation",
		continueSync: "Continue Sync",
		create: "Create New",
		import: "Import Menu",
		search: "Search Menus",
		manualMode: "Manual Mode",
		editMenu: "Edit Menu",
		deleteMenu: "Delete Menu",
		addProduct: "Add Product",
		searchProduct: "Search Product",
	},
	categories: {
		appetizers: "Appetizers",
		mainCourses: "Main Courses",
		desserts: "Desserts",
		nonAlcoholicDrinks: "Non-Alcoholic Drinks",
		alcoholicDrinks: "Alcoholic Drinks",
	},
	emptyState: {
		title: "Delivery Menu",
		message: "It looks like you haven't created any menu yet",
		create: "Create Menu",
		integration: "Import Menu",
	},
};
