export const allEventActionsReport = {
	title: "Event Actions",
	description: "List of all actions throughout the event.",
	filters: {
		employee: {
			label: "Employee Name",
			placeholder: "Enter or select the employee",
		},
		transaction: {
			label: "Transaction Type",
			placeholder: "Enter or select the type",
		},
	},
	columns: {
		id: "Id",
		date: "Date and Time",
		transactionType: "Transaction Type",
		employee: "Employee",
		user: "Customer",
		value: "Value",
		paymentMethod: "Payment Method",
		products: {
			title: "Products",
			name: "Product Name",
			count: "Quantity",
			value: "Value",
		},
	},
	filename: "event-actions-report",
	paymentMethodWithCashless: {
		ZigPosCredit: "ZigPos Credit",
		ZigPosCreditInInstallments: "ZigPos Credit in Installments",
		ZigPosDebit: "ZigPos Debit",
		CreditCard: "Credit Card",
		DebitCard: "Debit Card",
		Cash: "Cash",
		App: "App",
		Voucher: "Voucher",
		Anticipated: "Anticipated",
		Pix: "Pix",
		Cashless: "Cashless",
		IntegratedFoodVoucher: "Integrated Food Voucher",
		MBWay: "Mbway",
		Bonus: "Bonus",
		MinimumConsumption: "Minimum Consumption",
		DigitalCashless: "Digital Cashless",
	},
	openingInfoModal: "Event opening data",
	opening: "Opening",
	by: "By",
	openingTime: "Opening Time",
	openingBy: "Event Open By",
};
