export const importMenu = {
	title: "Configure delivery and integrations",
	description: "Integrate with a delivery partner and centralize your management in Zig.",
	info:
		"Some products may have complementary and optional items. You will be able to review more details and make adjustments in 'Products and Menus' after import.",
	selectAllCategories: "Select all categories",
	searchCategory: "Search category",
	amountItemsSelected: "<strong>{{amount}}</strong> items selected",
	cancelBtn: "Cancel",
	importItensSelected: "Import selected items",
	notProductFound: "No products found",
	returnToMenu: "Return to menu",
	menuNameByExternalType: "Delivery Menu ({{externalType}})",
	importMenuError: {
		title: "Unable to import your menu",
		description:
			"Something unexpected happened and we were unable to complete the import. Complete your integration and finalize your menu later.",
		completeWithoutMenuBtn: "Complete integration without menu",
		retryBtn: "Try to import again",
	},
	importMenuSuccess: {
		title: "Your menu has been successfully imported",
		description:
			"You can manage your menus in the 'Menus' tab of this module, as well as manage your products in the 'Products and Menus' module.",
		viewProductsBtn: "View products",
	},
	integrationSuccess: {
		title: "Integration completed",
		description:
			"Your integration has been successfully completed! You can manage your menus later in the 'Products and Menus' module.",
		viewProductsAndMenusBtn: "View products and menus",
	},
	loadingMessage: "Please wait while we identify the items in your menu",
	loadingMutation: "Please wait while we complete the integration",
	active: "Active",
	inactive: "Inactive",
};
