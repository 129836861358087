import { UsedBonusReportTranslations } from ".";

export const USED_BONUS_REPORT_FR_FR: UsedBonusReportTranslations = {
	usedBonus: "Bonus Utilisés",
	columns: {
		client: "Employé Responsable",
		dateTime: "Date et Heure",
		reason: "Raison",
		spentValueInBonus: "Valeur du Paiement en Bonus",
		total: "Total",
	},
};
