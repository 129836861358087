import { BonusReportAtEventTranslations } from "./bonus-report-at-event-pt-br";

export const BONUS_REPORT_AT_EVENT_EN_US: BonusReportAtEventTranslations = {
	title: "Bonus",
	description: "View all reports of bonus granting and usage",
	tabs: {
		given: "Bonus Given",
		used: "Bonus Used",
	},
};
