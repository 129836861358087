import { Bar, ImageUrl, MenuInfo } from "#api/enterprise-generated";
import { BarStore } from "#stores/bar";
import { setObservableData } from "#helpers/mobx/utils";
import { observable } from "mobx";

/**  @deprecated models should not ne used anymore */
export type barWithPlaceId = Bar & { placeId: string };

/**  @deprecated models should not ne used anymore */
export class BarModel implements Bar {
	@observable
	public barStore: BarStore;
	@observable
	public id!: string | null;
	@observable
	public name!: string;
	@observable
	public image!: ImageUrl | null;
	@observable
	public placeId!: string;
	@observable
	public storageId!: string;
	@observable
	public storageName!: string | null;
	@observable
	public isDeleted!: boolean | null;
	@observable
	public menus: MenuInfo[] = [];
	@observable
	internalIp!: string | null;

	@observable
	internalIps!: string[] | null;
	@observable
	fichaLogoUrl!: string | null;
	@observable
	public fichaLogoSha256!: string | null;

	constructor(barStore: BarStore, bar: barWithPlaceId) {
		this.barStore = barStore;
		this.sync(bar);
	}

	public sync = (bar: barWithPlaceId) => {
		//TODO
		setObservableData(this, bar);
	};
}
