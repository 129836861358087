import { merge } from "lodash-es";
import es_MXJson from "./es-MX.json";
import { PURCHASE_INVOICE_ES_MX } from "#pages/place/purchase-invoice/translations/purchase-invoice-es-mx";
import { IMPORT_FR_FR } from "#pages/place/purchase-invoice/import-invoice/translations/import-fr-fr";
import { CONCILIATION_ES_MX } from "#pages/backoffice/conciliation/translations/conciliation-es-mx";
import { BONUS_CATEGORIES_ES_MX } from "#pages/place/bonus-categories/translations/bonus-categories-es-mx";
import { delivery } from "./place/delivery";
import { USED_BONUS_REPORT_ES_MX } from "#pages/place/financial-reports/bonus-report/used-bonus-report/translations";
import { USER_BONUS_TABLE_ES_MX } from "#components/tables/reports/user-bonus-table/translations";
import { BONUS_REPORT_ES_MX } from "#pages/place/financial-reports/bonus-report/translations";
import { BONUS_REPORT_FOR_PLACE_ES_MX } from "#pages/place/financial-reports/bonus-report-for-place/translations";
import { BONUS_REPORT_AT_EVENT_ES_MX } from "#pages/event/event-reports/bonus-report-at-event/translations";
import { TRANSACTIONAL_HISTORY_TABLE_ES_MX } from "#pages/event/event-client-detail/client-info/transactional-history/transactional-history-table/translations";
import { TRANSACTIONAL_HISTORY_ES_MX } from "#pages/event/event-client-detail/client-info/transactional-history/translations";

const es_MX = merge(es_MXJson, {
	backoffice: { conciliation: CONCILIATION_ES_MX },
	place: {
		purchaseInvoiceV2: PURCHASE_INVOICE_ES_MX,
		import: IMPORT_FR_FR,
		delivery,
		bonusCategories: BONUS_CATEGORIES_ES_MX,
		usedBonusReport: USED_BONUS_REPORT_ES_MX,
		userBonusTable: USER_BONUS_TABLE_ES_MX,
		bonusReport: BONUS_REPORT_ES_MX,
		bonusReportForPlace: BONUS_REPORT_FOR_PLACE_ES_MX,
	},
	event: {
		userBonusTable: USER_BONUS_TABLE_ES_MX,
		bonusReportAtEvent: BONUS_REPORT_AT_EVENT_ES_MX,
		transactionalHistory: TRANSACTIONAL_HISTORY_ES_MX,
		transactionalHistoryTable: TRANSACTIONAL_HISTORY_TABLE_ES_MX,
	},
});

export { es_MX };
