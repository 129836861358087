export const deliveryMenus = {
	header: {
		mainTitle: "Cardápios",
		mainDescription: "Visualize, crie e edite cardápios de Delivery.",
		editDescription: "Visualize e edite seu cardápio.",
	},
	status: {
		active: "Ativo",
		pendingSync: "Sinc. Pendente",
		pendingImport: "Importação pendente",
		inactive: "Inativo",
		synced: "Sincronizado",
	},
	tabs: {
		importedMenus: "Cardápios importados",
		whiteLabelMenus: "Whitelabel",
	},
	menuCard: {
		message: "funcionamento 24h",
		at: "às",
		delivery: "Delivery",
		table: "Pedido na mesa",
		businessHour: "Horário de funcionamento",
		type: "Tipo",
		importedAt: "Importado em",
		createdAt: "Criado em",
		products: "Produtos",
		numberOfProducts: "{{ number }} Produtos",
		unsyncedProducts: "produtos não-sincronizados",
		notImportedProducts: "produtos não-importados",
	},
	actions: {
		seeMenu: "Ver cardápio",
		continueImport: "Continuar importação",
		continueSync: "Continuar sincronização",
		create: "Criar novo",
		import: "Importar cardápio",
		search: "Buscar cardápios",
		manualMode: "Modo manual",
		editMenu: "Editar cardápio",
		deleteMenu: "Excluir cardápio",
		addProduct: "Adicionar produto",
		searchProduct: "Buscar produto",
	},
	categories: {
		appetizers: "Entradas",
		mainCourses: "Pratos Principais",
		desserts: "Sobremesas",
		nonAlcoholicDrinks: "Bebidas não alcoólicas",
		alcoholicDrinks: "Drinks",
	},
	emptyState: {
		title: "Cardápio para delivery",
		message: "Parece que você ainda não tem nenhum cardápio criado",
		create: "Criar Cardápio",
		integration: "Importar cardápio",
	},
};
