import { UsedBonusReportTranslations } from ".";

export const USED_BONUS_REPORT_EN_US: UsedBonusReportTranslations = {
	usedBonus: "Used Bonuses",
	columns: {
		client: "Responsible Employee",
		dateTime: "Date and Time",
		reason: "Reason",
		spentValueInBonus: "Spent Value in Bonuses",
		total: "Total",
	},
};
