import * as ReactGA from "react-ga";

import enterprise from "#api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import dayjs from "dayjs";
import { PlaceModel } from "#models/place";
import { ReportModel } from "#models/report";
import { RootStore } from ".";

import { action, observable } from "mobx";
import {
	AddressFromCep,
	AdjustResponse,
	AppTransactionDetailsPaginationResponse,
	AppTransactionsTotalizer,
	BackofficeProductCmvWithQuantity,
	BaseProduct,
	BasicStorageUnit,
	BiConsumptionTimeline,
	BillPayment,
	CardReturnEmployee,
	Cashier,
	Client,
	ClientByReport,
	ClientOnlineFichaHistory,
	ConsolidatedExtract,
	CreateDiscountCategoryRequest,
	CreateRefundReason,
	DayResume,
	Debtor,
	DiscountCategoriesResponse,
	DiscountCategoryFilters,
	DiscountCategoryResponse,
	DiscountEmployee,
	EditProductAlert,
	EditRefundReason,
	EmployeeBaseResponse,
	EmployeeProductRankingReport,
	EmployeeReportEmployees,
	EmployeesByDiscountCategory,
	EmployeeTipReport,
	EmployeeTipReportFilters,
	Entrance,
	EntranceSold,
	EntranceUser,
	EventResumeSection,
	EverestConfig,
	ExtractDayResumeSection,
	ExtractFlowRow,
	ExtractResume,
	FichaExtraConfig,
	FinanceBiReportEvents,
	GetPlaceZendeskTicketsFilters,
	GrabAndGoProduct,
	ListPendingPlaceContracts,
	MinimumConsumptionReport,
	NewPlace,
	NewProductAlert,
	OpenBillCharge,
	OpenBillChargeDetails,
	OpenedBillPayment,
	OpenedBillPaymentV2,
	OpenSale,
	OpenSaleReport,
	Pagination,
	PaginationPosTransaction,
	PendingOnlineRechargeReport,
	PeriodTime,
	PixCharge,
	Place,
	PlaceContract,
	PlaceContractListFilters,
	PlaceContractListPaginated,
	PlaceData,
	PlaceFeature,
	PlaceProduct,
	PlaceSettingsFichaSectionRequest,
	PlaceSettingsFichaSectionResponse,
	PlaceSettingsGeneralSectionRequest,
	PlaceSettingsGeneralSectionResponse,
	PlaceSettingsPdvSectionRequest,
	PlaceSettingsPdvSectionResponse,
	PlaceSettingsPdvSectionReturn,
	PlaceSettingsPlaceFeatureSectionRequest,
	PlaceSettingsPlaceFeatureSectionResponse,
	PlaceWithFeatures,
	PosMachineDayResume,
	PosTransaction,
	ProductAlert,
	ProductBarReport,
	ProductBurnedTicketSold,
	ProductLostReport,
	ProductMasterKind,
	ProductRankingReport,
	ProductSold,
	ProductSoldV2,
	ProductToZigTag,
	RappiDiscount,
	RechargeByZigCode,
	RechargeByZigDevice,
	RechargeExpireDayResume,
	RechargeResults,
	RechargeTypeEnum,
	RefundedProduct,
	RefundReason,
	RefundReasonResult,
	SellerReport,
	SellerTransaction,
	SimplifiedPlace,
	SingleProductReturnResponse,
	SoldEntranceWithEmployee,
	TipRemovedList,
	TotemConfigsRequest,
	TotemPlaceConfigResponse,
	TotemTransactionFilters,
	TotemTransactionResponse,
	TotemTransactionTotalizerResponse,
	TransactionSource,
	TransactionTransfer,
	UpdateDiscountCategoryRequest,
	UsedPromotion,
	UserAlert,
	UsersEntrancesConsumedOverview,
	UsersEntrancesConsumedReportFilters,
	UsersEntrancesConsumedReportResponse,
	UserUsedPromotion,
	ZendeskTicketsPaginationResponse,
	ZigDeviceGroup,
	ZigDeviceWithGroupAndBalance,
	ZigLocation,
} from "#api/enterprise-generated";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import i18n from "#i18n/index";

export type placeFeatures =
	| "ame"
	| "backoffice"
	| "bonusDigio"
	| "centralStorage"
	| "cmv"
	| "dailyWithdraw"
	| "delivery"
	| "discountCategories"
	| "everest"
	| "ficha"
	| "finishPdvOperation"
	| "fiscalDashboard"
	| "fullPromotionModule"
	| "grabAndGoNoFollow"
	| "hasBirthdateInRegister"
	| "hasEmailInRegister"
	| "hasFiscal"
	| "hasGrabAndGo"
	| "hasImageInRegister"
	| "hasMandatoryTip"
	| "hasMesa"
	| "hasUserAppBlocked"
	| "innovaro"
	| "keepOfflineKey"
	| "listSell"
	| "multivendor"
	| "productReturn"
	| "fullPromotionModule"
	| "nevoa"
	| "newRechargeReturn"
	| "pdvFiscal"
	| "printOfflinePerLan"
	| "rappiPay"
	| "sellEntranceonRegister"
	| "table"
	| "usesReceptive"
	| "usesWifiKitchenPrinter"
	| "waitList"
	| "womanSecurityCalls"
	| "beerTap"
	| "printByTableRange"
	| "whatsUp"
	| "showEstimatedTax"
	| "confirmWithdraw"
	| "appQrCodeSell"
	| "myTapp"
	| "beerPass"
	| "ivaVendus"
	| "ivaFactura"
	| "burnPaperTickets"
	| "promoter"
	| "enableUIDAsAnonymousDocument"
	| "enableRechargeByZigCode"
	| "shouldHideExtractFlow"
	| "menu"
	| "accountBills"
	| "validateRetainedValuesWhenWithdrawing"
	| "ficha"
	| "hawkerModule"
	| "vendorAnticipation"
	| "onlyOnlineMesaService"
	| "block20PercentWithdraw"
	| "zeDelivery"
	| "storage"
	| "appPaymentWithNfc"
	| "dailyWithdrawVendor"
	| "buyAndEarn";

export interface BackofficeProductCmvEnhanced extends BackofficeProductCmvWithQuantity {
	name: string;
	price: number;
	ube: BasicStorageUnit;
	floatUnitCost: number;
	floatTotalCost: number;
	hasProductRule: boolean;
	fiscalCode: string | null;
}

interface ProductWithCategory {
	categoryId: string;
	categoryName: string;
	parentId: string;
	products: PlaceProduct[];
}

interface IPlaceAndPeriodData {
	since: Date;
	until: Date;
	placeId: string;
}

interface IWaiterTransactionAtEventData {
	placeId: string;
	waiterId: string;
	since: Date;
	until: Date;
}

interface ISellerProductsData {
	placeId: string;
	sellerId: string;
	since: Date;
	until: Date;
}

interface IEntrancesSoldData {
	placeId: string;
	start: Date;
	end: Date;
}

function middleDay(date: Date) {
	return dayjs(date).hour(12).toDate();
}

const t = i18n.t;

export default class PlaceStore {
	@observable
	public debtorsReport: ReportModel<Debtor[], IPlaceAndPeriodData> = new ReportModel(
		({ placeId, since, until }) =>
			enterprise.debtorsInPlace({
				placeId,
				since,
				until,
			}),
	);

	@observable
	public entrancesSoldToUserAtPlace: EntranceUser[] = [];
	@observable
	public loading: boolean = false;
	@observable
	public productsSoldAtPlaceByBar: ProductSold[] = [];
	@observable
	public compareProductsSoldByBar: ProductBarReport[] = [];

	@observable
	public clientBaseReport: ReportModel<Client[], IPlaceAndPeriodData> = new ReportModel(
		({ since, until, placeId }) =>
			enterprise.clientsAtPlace({
				since: middleDay(since),
				until: middleDay(until),
				placeId,
			}),
	);

	@observable
	public waiterTransactionsReport: ReportModel<
		SellerTransaction[],
		IWaiterTransactionAtEventData
	> = new ReportModel(({ placeId, waiterId, since, until }) =>
		enterprise.getSellerDetailsPlace({
			placeId,
			sellerId: waiterId,
			since,
			until,
		}),
	);

	@observable
	public cashierBalanceReport: ReportModel<
		Cashier[],
		IPlaceAndPeriodData
	> = new ReportModel(({ since, until, placeId }) =>
		enterprise.cashierDetailsAtPlace({
			since: middleDay(since),
			until: middleDay(until),
			placeId,
		}),
	);

	@observable
	public soldProductsReport = new fetchModel<
		{ since: Date; until: Date; placeId: string; sources?: TransactionSource[] | null },
		ProductSoldV2[]
	>({
		fnPromise: args => enterprise.getProductsSoldAtEventInPeriodV2(args),
	});

	@observable
	public refundedProductsAtPlace: ReportModel<
		RefundedProduct[],
		IPlaceAndPeriodData
	> = new ReportModel(({ since, until, placeId }) =>
		enterprise.getRefundedProductsAtPlace({
			placeId,
			since,
			until,
		}),
	);
	@observable
	public minimumConsumptionReportForPlace: ReportModel<
		MinimumConsumptionReport[],
		IPlaceAndPeriodData
	> = new ReportModel(({ since, until, placeId }) =>
		enterprise.getMinimumConsumptionReportForPlace({
			placeId,
			since,
			until,
		}),
	);

	@observable
	public waiterReport: ReportModel<SellerReport[], IPlaceAndPeriodData> = new ReportModel(
		({ since, until, placeId }) =>
			enterprise.getSellerReportForPlace({
				placeId,
				since: middleDay(since),
				until: middleDay(until),
			}),
	);

	public getSellerReportForPlace = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			sources?: TransactionSource[] | null;
		},
		SellerReport[]
	>({
		fnPromise: ({ placeId, since, until, sources }) =>
			enterprise.getSellerReportForPlace({
				placeId,
				since,
				until,
				sources,
			}),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public entrancesSoldReport: ReportModel<
		EntranceSold[],
		IEntrancesSoldData
	> = new ReportModel(({ placeId, start, end }) =>
		enterprise.entrancesSoldAtPlace({
			since: middleDay(start),
			until: middleDay(end),
			placeId,
		}),
	);

	public rappiDiscount = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		RappiDiscount[]
	>({
		fnPromise: args => enterprise.getRappiDiscountsAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public discountsAtPlace = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		DiscountEmployee[],
		DiscountEmployee[]
	>({
		fnPromise: args => enterprise.getDiscountsAtPlaceByEmployee(args),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public waiterProductsReport: ReportModel<
		ProductSold[],
		ISellerProductsData
	> = new ReportModel(({ placeId, sellerId, since, until }) =>
		enterprise.getSellerProductDetailsPlace({
			placeId,
			sellerId,
			since,
			until,
		}),
	);

	public dailyExtractReport = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		DayResume[],
		DayResume[]
	>({
		fnPromise: ({ placeId, since, until }) =>
			enterprise.extract({ placeId, since: middleDay(since), until: middleDay(until) }),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public extractFlow = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		ExtractFlowRow[],
		ExtractFlowRow[]
	>({
		fnPromise: ({ placeId, since, until }) =>
			enterprise.getExtractFlow({
				placeId,
				since: middleDay(since),
				until: middleDay(until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public extractResumeReport: ReportModel<
		ExtractResume,
		{ placeId: string }
	> = new ReportModel(args => enterprise.getExtractResume(args));

	@observable
	public entranceSoldByEmployeeAtPlace: ReportModel<
		SoldEntranceWithEmployee[],
		IPlaceAndPeriodData
	> = new ReportModel(({ placeId, since, until }) =>
		enterprise.getEntrancesSoldByEmployeeAtPlace({
			placeId,
			since,
			until,
		}),
	);

	public usedPromotion: fetchModel<IPlaceAndPeriodData, UsedPromotion[]> = new fetchModel(
		{
			fnPromise: args =>
				enterprise.getPromotionsUsedAtPlace({
					...args,
					since: middleDay(args.since),
					until: middleDay(args.until),
				}),
		},
	);

	@observable
	public consolidateExtractPromise: IPromiseBasedObservable<ConsolidatedExtract> | null = null;

	@observable
	public ajustmentsExtract: AdjustResponse[] | [] = [];

	@observable
	public extractFlowData: ExtractFlowRow[] = [];

	@observable
	public fetchPlacesPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchPlacePromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public editPlacePromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public addPlacePromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchExpiredExtractPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public places: PlaceModel[] = [];
	@observable
	public place: PlaceModel | null = null;
	@observable
	public expiredRecharges: RechargeExpireDayResume[] = [];
	@observable
	public extractDayResumeSections: ExtractDayResumeSection[] | undefined;

	@observable
	public placeFeatures: PlaceFeature[] = [];

	@observable
	public fetchPosMachineDayResumesPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public posMachineDayResumes: PosMachineDayResume[] | undefined = undefined;

	@observable
	public fetchPosMachineTransactionsPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public posMachineTransactions: PosTransaction[] | undefined = undefined;

	@observable
	public ficha: FichaExtraConfig = {
		fichaLogo: "",
		fichaText: "",
	};

	@action
	public fetchPosMachineDayResumes = (from: Date, until: Date) => {
		this.posMachineDayResumes = undefined;
		this.fetchPosMachineDayResumesPromise = fromPromise(
			enterprise
				.getConsolidatedPosMachineTransactions({
					placeId: this.place!.id!,
					since: from,
					until,
				})
				.then(value => {
					this.posMachineDayResumes = value;
				})
				.catch((err: Error) => showErrorNotification(err.message)),
		);
	};

	@observable
	public posMachineTransactionsPagination?: PaginationPosTransaction = undefined;

	@action
	public fetchPosMachineTransactions = async (
		placeId: string,
		dates: Date[],
		page: number,
		searchTerm: string | null,
		itemsPerPage: number | null,
	) => {
		this.posMachineTransactionsPagination = undefined;

		try {
			this.posMachineTransactionsPagination = await enterprise.getPosMachineTransactions({
				placeId,
				dates,
				page,
				searchTerm,
				itemsPerPage,
			});
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public rootStore: RootStore;
	@observable
	public placePassword?: string | null = null;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.fetchPlacesPromise = null;
		this.fetchPlacePromise = null;
		this.editPlacePromise = null;
		this.addPlacePromise = null;
		this.fetchExpiredExtractPromise = null;
		this.places = [];
		this.place = null;
		this.expiredRecharges = [];
		this.ajustmentsExtract = [];
		this.openedBillPayments.reset();
		this.rappiDiscount.reset();
		this.soldBaseProduct.reset();
		this.productAlerts.reset();
		this.userAlerts.reset();
		this.newGetPlaces.reset();
		this.getEntranceResumeForPlaceInSections.reset();
		this.getBackofficeCurrentProductsCmvV2.reset();
		this.getPlaceProductsNew.reset();
		this.getDiscountCategory.reset();
		this.getEmployees.reset();
		this.ficha = { fichaLogo: "", fichaText: "" };
	}

	public cleanPlaceFeatures() {
		this.placeFeatures = [];
	}

	public cleanPlacePassword() {
		this.placePassword = null;
	}

	@action
	public updateNoteFromServer = ({
		defaultReportSinceDate,
		defaultReportUntilDate,
		...incomePlace
	}: Place) => {
		const formattedPlace = {
			...incomePlace,
			defaultReportSinceDate: defaultReportSinceDate
				? dayjs(defaultReportSinceDate).startOf("day").toDate()
				: null,
			defaultReportUntilDate: defaultReportUntilDate
				? dayjs(defaultReportUntilDate).endOf("day").toDate()
				: null,
		};
		let place = this.places.find(t => t && t.id === incomePlace.id);
		if (!place) {
			place = new PlaceModel(
				this,
				this.rootStore.barStore,
				this.rootStore.eventStore,
				formattedPlace,
			);
			this.places.push(place);
		} else {
			place.sync(formattedPlace);
		}
		return place;
	};

	@action
	public resolve = (incomePlace: Place) => this.updateNoteFromServer(incomePlace);

	public fetchPlacesWithFeatures = new fetchModel<{}, PlaceWithFeatures[]>({
		fnPromise: () => enterprise.getPlacesWithFeatures(),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchPlaces = () =>
		(this.fetchPlacesPromise = fromPromise(
			enterprise.getPlaces().then(places => {
				places.forEach(this.resolve);
			}),
		));

	public newGetPlaces = new fetchModel<{}, Place[], Place[]>({
		fnPromise: () => enterprise.getPlaces(),
		onError: err => showErrorNotification(err.message),
		transform: value =>
			value.map(p => ({
				...p,
				defaultReportSinceDate: p.defaultReportSinceDate
					? dayjs(p.defaultReportSinceDate).startOf("day").toDate()
					: null,
				defaultReportUntilDate: p.defaultReportUntilDate
					? dayjs(p.defaultReportUntilDate).endOf("day").toDate()
					: null,
			})),
	});

	public getRechargeValueTransactedByEvents = new fetchModel<
		{ eventIds: string[] },
		number
	>({
		fnPromise: args => enterprise.getRechargeValueTransacted(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public getPlaceFeatures = async (placeId: string) => {
		try {
			this.placeFeatures = await enterprise.getPlaceFeatures({ placeId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public hasFeature = (featureId: placeFeatures) => {
		const feature = this.placeFeatures.find(p => p.id === featureId);
		return feature && feature.isActive ? true : false;
	};

	@action
	public setPlaceFeatures = async (placeId: string, featuresIds: string[]) => {
		try {
			await enterprise.setZigPlaceFeatures({ placeId, featureIds: featuresIds });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public getFichaExtraConfig = async (placeId: string) => {
		try {
			const getFicha = await enterprise.getFichaExtraConfig({ placeId });

			this.ficha = {
				fichaLogo: getFicha.fichaLogo,
				fichaText: getFicha.fichaText,
			};
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public async setCurrentPlace(placeId: string) {
		try {
			let place = this.places.find(p => p.id === placeId);
			if (place === undefined) {
				this.getPlaceFeatures(placeId);
				await this.fetchPlaces();
				place = this.places.find(p => p.id === placeId);
			}
			this.place = place || null;
			this.getPlaceFeatures(placeId);
			this.rootStore.itemStore.clean();
			this.rootStore.comboStore.clean();
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	}

	@action
	public unsetCurrentPlace() {
		this.place = null;
	}

	@action
	public fetchPlace = (placeId: string) =>
		(this.fetchPlacePromise = fromPromise(
			enterprise
				.getPlace({ placeId })
				.then(p => {
					const { place, products, combos } = p;
					this.resolve(place);
					this.rootStore.itemStore.resolve(products);
					this.rootStore.comboStore.combos = combos;
				})
				.catch(console.error),
		));

	@action
	public editPlace = async (place: NewPlace, image?: Buffer): Promise<boolean> =>
		enterprise
			.editPlace({ place: { ...place }, image: image || undefined })
			.then(place => {
				this.resolve(place);
				showSuccessNotification(t("store:placeStore.editPlace"));
				ReactGA.event({
					category: "User",
					action: "edit place",
				});
				return true;
			})
			.catch((err: any) => {
				if (err instanceof Error) {
					showErrorNotification(err.message);
				}
				return false;
			});

	@action
	public getPlacePassword = async (placeId: string) => {
		try {
			const password = await enterprise.getPlacePassword({ placeId });
			this.placePassword = password !== null ? password : undefined;
		} catch (error) {
			console.error(error);
		}
	};

	@action
	public editPlacePassword = (placeId: string, password: string) => {
		try {
			const verifiedPassword = password.length === 0 ? null : password;
			enterprise.editPlacePassword({ placeId, pass: verifiedPassword });
		} catch (error) {
			console.error(error);
		}
	};

	@observable
	public placeProductsWithCategories: ProductWithCategory[] = [];

	@observable
	public placeProducts: PlaceProduct[] = [];

	public placeProductsById: Record<string, PlaceProduct | undefined> = {};

	public updatePlaceProductsById = () => {
		this.placeProductsById = this.placeProducts.reduce(
			(acc, cur) => ({ ...acc, [cur.id!]: cur }),
			{},
		);
	};

	public categoryProductsObject(
		arrCategories: any[],
		products: PlaceProduct[],
	): ProductWithCategory[] {
		return arrCategories.map((category: any) => ({
			categoryId: category.id,
			categoryName: category.name,
			parentId: category.parentId,
			products: products.filter(product => product.categoryId === category.id),
		}));
	}

	@observable
	public isFetchingGetPlaceProductsWithCategories: boolean = false;

	@action
	public getPlaceProductsWithCategories = async (placeId: string) => {
		if (!this.isFetchingGetPlaceProductsWithCategories) {
			this.isFetchingGetPlaceProductsWithCategories = true;
			try {
				this.placeProducts = await enterprise.getPlaceProducts({ placeId });
				this.updatePlaceProductsById();
				const categories = await enterprise.getPlainCategories();
				this.placeProductsWithCategories = this.categoryProductsObject(
					categories,
					this.placeProducts,
				);
			} catch (err) {
				if (err instanceof Error) {
					showErrorNotification(err.message);
				}
				console.error(err);
			}
			this.isFetchingGetPlaceProductsWithCategories = false;
		}
	};

	@action
	public fetchExpiredExtract = (since: Date, until: Date) => {
		this.fetchExpiredExtractPromise = fromPromise(
			enterprise
				.getRechargeExpireExtract({
					placeId: this.place!.id!,
					since: middleDay(since),
					until: middleDay(until),
				})
				.then(expRecharges => {
					this.expiredRecharges = expRecharges;
				})
				.catch(err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
				}),
		);
	};

	@action
	public fetchDebtorsReport = async (since: Date, until: Date) => {
		try {
			await this.debtorsReport.fetch({
				args: {
					since,
					until,
					placeId: this.place!.id!,
				},
			});
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public fetchClientBaseReport = (since: Date, until: Date) => {
		this.clientBaseReport.fetch({
			args: {
				since: middleDay(since),
				until: middleDay(until),
				placeId: this.place!.id!,
			},
		});
	};

	@action
	public fetchWaiterTransactions = (waiterId: string, since: Date, until: Date) => {
		this.waiterTransactionsReport.fetch({
			args: {
				placeId: this.place!.id!,
				waiterId,
				since,
				until,
			},
		});
	};

	@action
	public fetchDeliveredProductsByBarReport = async (
		since: Date,
		until: Date,
		barIds: string[],
		sources?: TransactionSource[] | null,
		productMasterKinds?: ProductMasterKind[] | null,
	) => {
		this.loading = true;
		this.productsSoldAtPlaceByBar = await enterprise.deliveredProductsByBarsAtPlace({
			since,
			until,
			placeId: this.place!.id!,
			barIds,
			sources,
			productMasterKinds,
		});
		this.loading = false;
	};

	@action
	public fetchCompareProductsSoldByBars = async (
		since: Date,
		until: Date,
		barIds: string[],
		sources?: TransactionSource[] | null,
		productMasterKinds?: ProductMasterKind[] | null,
	): Promise<ProductBarReport[]> => {
		this.loading = true;
		const response = await enterprise.compareProductsSoldByBars({
			since,
			until,
			placeId: this.place!.id!,
			barIds,
			sources,
			productMasterKinds,
		});
		this.compareProductsSoldByBar = response;
		this.loading = false;
		return response;
	};

	@action
	public fetchSoldProductsReport = (
		since: Date,
		until: Date,
		sources?: TransactionSource[] | null,
	) => {
		this.soldProductsReport.fetch({
			since: middleDay(since),
			until: middleDay(until),
			placeId: this.place!.id!,
			sources,
		});
	};

	@action
	public fetchRefundedProductsAtPlace = (since: Date, until: Date) => {
		this.refundedProductsAtPlace.fetch({
			args: {
				since,
				until,
				placeId: this.place!.id!,
			},
		});
	};
	public fetchMinimumConsumptionReportForPlace = (since: Date, until: Date) => {
		this.minimumConsumptionReportForPlace.fetch({
			args: {
				since,
				until,
				placeId: this.place!.id!,
			},
		});
	};

	@action
	public fetchWaiterReport = (since: Date, until: Date) => {
		this.waiterReport.fetch({
			args: {
				since: middleDay(since),
				until: middleDay(until),
				placeId: this.place!.id!,
			},
		});
	};

	@action
	public fetchWaiterProductsReport = async (
		sellerId: string,
		since: Date,
		until: Date,
	) => {
		return await this.waiterProductsReport.fetch({
			args: {
				placeId: this.place!.id!,
				sellerId,
				since,
				until,
			},
		});
	};

	@action
	public fetchEntrancesSoldReport = (start: Date, end: Date) => {
		this.entrancesSoldReport.fetch({
			args: {
				placeId: this.place!.id!,
				start: middleDay(start),
				end: middleDay(end),
			},
		});
	};

	@action
	public fetchEntranceSoldByEmployeeAtPlace = (since: Date, until: Date) => {
		this.entranceSoldByEmployeeAtPlace.fetch({
			args: {
				placeId: this.place!.id!,
				since: middleDay(since),
				until: middleDay(until),
			},
		});
	};

	@action
	public fetchEntrancesSoldToUserAtPlace = async (since: Date, until: Date) => {
		this.loading = true;
		try {
			const resp = await enterprise.getEntrancesSoldToUserAtPlace({
				placeId: this.place!.id!,
				since,
				until,
			});
			this.entrancesSoldToUserAtPlace = resp;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
		this.loading = false;
	};

	@action
	public fetchDailyExtractReport = (since: Date, until: Date) => {
		this.dailyExtractReport.fetch({
			placeId: this.place!.id!,
			since: middleDay(since),
			until: middleDay(until),
		});
	};

	@action
	public fetchExtractFlow = (since: Date, until: Date) => {
		this.extractFlow.fetch({
			placeId: this.place!.id!,
			since: middleDay(since),
			until: middleDay(until),
		});
	};

	@action
	public fetchExtractResume = () => {
		this.extractResumeReport.fetch({ args: { placeId: this.place!.id! } });
	};

	@action
	public fetchCashierBalanceReport = (since: Date, until: Date) => {
		this.cashierBalanceReport.fetch({
			args: {
				since: middleDay(since),
				until: middleDay(until),
				placeId: this.place!.id!,
			},
		});
	};

	@observable
	public consolidatedExtract?: ConsolidatedExtract = undefined;

	@action
	public getConsolidateExtract = async (since: Date, until: Date) => {
		this.consolidatedExtract = undefined;
		try {
			this.consolidatedExtract = await enterprise.getConsolidatedExtractInSections({
				placeId: this.place!.id!,
				since,
				until,
			});
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public getAjustmentsExtract = async (since: Date, until: Date) => {
		try {
			this.ajustmentsExtract = await enterprise.getExtractAdjusts({
				placeId: this.place!.id!,
				since: middleDay(since),
				until: middleDay(until),
			});
		} catch (err) {
			console.error("getAjustmentsExtract", err);
		}
		return this.ajustmentsExtract;
	};

	@action
	public getExtractFlow = async (since: Date, until: Date) => {
		try {
			this.extractFlowData = await enterprise.getExtractFlow({
				placeId: this.place!.id!,
				since: middleDay(since),
				until: middleDay(until),
			});
		} catch (err) {
			console.error("extractFlowData", err);
		}
		return this.extractFlowData;
	};

	@action
	public getExtractDayResumeInSections = async (date: Date) => {
		this.extractDayResumeSections = await enterprise.getExtractDayResumeInSections({
			placeId: this.place!.id!,
			date: middleDay(date),
		});
		return this.extractDayResumeSections;
	};

	@observable
	public employeeReportEmployees: EmployeeReportEmployees[] = [];

	@action
	public getEmployeeReport = async (since: Date, until: Date) => {
		try {
			const employeeReport = await enterprise.getPlaceEmployeeReport({
				placeId: this.place!.id!,
				since: middleDay(since),
				until: middleDay(until),
			});
			this.employeeReportEmployees = employeeReport.employees;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@observable
	public financeBiReportEvents: FinanceBiReportEvents[] = [];

	public openedBillPayments = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		OpenedBillPayment[]
	>({
		fnPromise: args =>
			enterprise.getOpenedBillPaymentsAtPlace({
				placeId: args.placeId,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public soldBaseProduct = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			sources?: TransactionSource[] | null;
		},
		BaseProduct[]
	>({
		fnPromise: args => enterprise.getSoldBaseProductsAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public getPlaceProducts = async (placeId: string) => {
		try {
			this.placeProducts = await enterprise.getPlaceProducts({ placeId });
			this.updatePlaceProductsById();
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public getPlaceProductsNew = new fetchModel<{ placeId: string }, PlaceProduct[]>({
		fnPromise: args => enterprise.getPlaceProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public productAlerts = new fetchModel<{ placeId: string }, ProductAlert[]>({
		fnPromise: args => enterprise.getStorageAlerts(args),
		onError: err => showErrorNotification(err.message),
	});

	public createStorageAlerts = async (placeId: string, alerts: NewProductAlert[]) => {
		try {
			const storageAlert = await enterprise.createStorageAlerts({ placeId, alerts });
			if (storageAlert.error.length > 0) {
				showErrorNotification(storageAlert.error[0].reason);
			} else {
				showSuccessNotification(t("store:placeStore.createStorageAlerts"));
			}
			return storageAlert;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public editStorageAlerts = async (placeId: string, alerts: EditProductAlert[]) => {
		try {
			const storageAlert = await enterprise.editStorageAlerts({ placeId, alerts });
			if (storageAlert.error.length > 0) {
				showErrorNotification(storageAlert.error[0].reason);
			} else {
				showSuccessNotification(t("store:placeStore.editStorageAlerts"));
			}
			return storageAlert;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public deleteStorageAlerts = async (placeId: string, ids: string[]) => {
		try {
			await enterprise.deleteStorageAlerts({ placeId, ids });
			showSuccessNotification(t("store:placeStore.deleteStorageAlerts"));
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public userAlerts = new fetchModel<{ placeId: string }, UserAlert[]>({
		fnPromise: args => enterprise.getEmailsToMinimumStorageAlert(args),
		onError: err => showErrorNotification(err.message),
	});

	public addEmailToMinimumStorageAlert = async (placeId: string, users: UserAlert[]) => {
		try {
			await enterprise.addEmailToMinimumStorageAlert({ placeId, users });
			showSuccessNotification(t("store:placeStore.addEmailToMinimumStorageAlert"));
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public removeEmailToMinimumStorageAlert = async (placeId: string, users: string[]) => {
		try {
			await enterprise.removeEmailToMinimumStorageAlert({ placeId, emails: users });
			showSuccessNotification(t("store:placeStore.removeEmailToMinimumStorageAlert"));
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	public getZigDevicesWithGroups = new fetchModel<
		{ placeId: string },
		ZigDeviceWithGroupAndBalance[]
	>({
		fnPromise: args => enterprise.getZigDevicesWithGroups(args),
		onError: err => showErrorNotification(err.message),
	});

	public getZigDeviceGroupsByPlace = new fetchModel<
		{ placeId: string },
		ZigDeviceGroup[]
	>({
		fnPromise: args => enterprise.getZigDeviceGroupsByPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public rechargeByZigDeviceGroup = new fetchModel<
		{ placeId: string; zigDeviceGroupId: string; recharge: RechargeByZigDevice },
		void
	>({
		fnPromise: args => enterprise.rechargeByZigDeviceGroup(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("event:panel.importConsumptionZigCode.rechargeSuccess")),
	});

	public rechargeByZigCodes = new fetchModel<
		{ placeId: string; rechargeType: RechargeTypeEnum; recharges: RechargeByZigCode[] },
		RechargeResults
	>({
		fnPromise: args => enterprise.rechargeByZigCodes(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: e => {
			if (e.error.length) {
				e.error.forEach(error => {
					showErrorNotification(error.reason);
				});
			}

			if (e.success.length) {
				showSuccessNotification(
					t("event:panel.importConsumptionZigCode.rechargeSuccess"),
				);
			}
		},
	});

	public blockZigDeviceByZigCodes = new fetchModel<
		{
			zigCodes: string[];
			placeId: string;
		},
		void
	>({
		fnPromise: args => enterprise.blockZigDeviceByZigCodes(args),
		onError: err => showErrorNotification(err.message),
	});

	public unblockZigDeviceByZigCodes = new fetchModel<
		{
			zigCodes: string[];
			placeId: string;
		},
		void
	>({
		fnPromise: args => enterprise.unblockZigDeviceByZigCodes(args),
		onError: err => showErrorNotification(err.message),
	});

	public createZigDeviceGroup = new fetchModel<
		{ placeId: string; name: string },
		ZigDeviceGroup
	>({
		fnPromise: args => enterprise.createZigDeviceGroup(args),
		onError: err => showErrorNotification(err.message),
	});

	public editZigDeviceGroupOfZigDevices = new fetchModel<
		{
			zigCodes: string[];
			zigDeviceGroupId?: string | null | undefined;
		},
		void
	>({
		fnPromise: args => enterprise.editZigDeviceGroupOfZigDevices(args),
		onError: err => showErrorNotification(err.message),
	});

	public deletePendingOnlineRechargesByZigDeviceUids = new fetchModel<
		{
			placeId: string;
			zigDeviceUids: string[];
		},
		void
	>({
		fnPromise: args => enterprise.deletePendingOnlineRechargesByZigDeviceUids(args),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public getFinanceBiReport = async (since: Date, until: Date) => {
		try {
			const employeeReport = await enterprise.getFinanceBiReport({
				placeId: this.place!.id!,
				since: dayjs(since).startOf("day").toDate(),
				until: dayjs(until).startOf("day").toDate(),
			});
			this.financeBiReportEvents = employeeReport.events;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@observable
	public clientsBIReport?: ClientByReport;

	@action
	public getClientsBiReport = async (since: Date, until: Date) => {
		try {
			this.clientsBIReport = await enterprise.getClientsBiReport({
				placeId: this.place!.id!,
				since: since,
				until: until,
			});
			return this.clientsBIReport;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
		}
	};

	@action
	public exportAllPlaceProductsXlsx = async (placeId: string) => {
		try {
			return await enterprise.getProductsFromPlaceReportXls({ placeId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public entrances = new fetchModel<
		{
			placeId: string;
		},
		Entrance[]
	>({
		fnPromise: args => enterprise.getAllEntrances(args),
		onError: err => showErrorNotification(err.message),
	});

	public orgPlaces = new fetchModel<{}, Place[], Place[]>({
		fnPromise: () => enterprise.getPlaces(),
		onError: err => showErrorNotification(err.message),
		transform: value =>
			value.map(p => ({
				...p,
				defaultReportSinceDate: p.defaultReportSinceDate
					? dayjs(p.defaultReportSinceDate).startOf("day").toDate()
					: null,
				defaultReportUntilDate: p.defaultReportUntilDate
					? dayjs(p.defaultReportUntilDate).endOf("day").toDate()
					: null,
			})),
	});

	public simplifiedPlaces = new fetchModel<{}, SimplifiedPlace[], SimplifiedPlace[]>({
		fnPromise: () => enterprise.getSimplifiedPlaces(),
		transform: places => places.sort((a, b) => a.name.localeCompare(b.name)),
		onError: err => showErrorNotification(err.message),
	});

	public getGrabAndGoProducts = new fetchModel<
		{
			placeId: string;
		},
		GrabAndGoProduct[]
	>({
		fnPromise: args => enterprise.getGrabAndGoProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	private changeGrabAndGoProductStatus = (id: string, status: boolean) => {
		if (!this.getGrabAndGoProducts.value) return;
		const product = this.getGrabAndGoProducts.value.find(p => p.id === id);
		if (product) product.isActiveForGrabAndGo = status;
	};

	public activateGrabAndGoProduct = new fetchModel<
		{
			placeId: string;
			productId: string;
		},
		void
	>({
		fnPromise: args => enterprise.activateGrabAndGoProduct(args),
		onFetching: args => this.changeGrabAndGoProductStatus(args.productId, true),
		onError: (err, args) => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			this.changeGrabAndGoProductStatus(args.productId, false);
		},
	});

	public deactivateGrabAndGoProduct = new fetchModel<
		{
			placeId: string;
			productId: string;
		},
		void
	>({
		fnPromise: args => enterprise.deactivateGrabAndGoProduct(args),
		onFetching: args => this.changeGrabAndGoProductStatus(args.productId, false),
		onError: (err, args) => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			this.changeGrabAndGoProductStatus(args.productId, true);
		},
	});

	public devolutionCardReportAtPlace = new fetchModel<
		{ placeId: string; until: Date; since: Date },
		CardReturnEmployee[]
	>({
		fnPromise: args => enterprise.getCardActivationReturnReportByEmployeeAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public getTipsRemovedAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		TipRemovedList[]
	>({
		fnPromise: args => enterprise.getTipsRemovedAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public getOpenedBillPaymentsAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		OpenedBillPaymentV2[]
	>({
		fnPromise: args => enterprise.getOpenedBillPaymentsAtPlaceV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public getConsumptionTimeLineAtPlaceBy30Min = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		BiConsumptionTimeline[]
	>({
		fnPromise: args => enterprise.getConsumptionTimeLineAtPlaceBy30Min(args),
		onError: err => showErrorNotification(err.message),
	});

	public getConsumptionTimeLineAtPlaceBy30MinByDay = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		BiConsumptionTimeline[]
	>({
		fnPromise: args => enterprise.getConsumptionTimeLineAtPlaceBy30MinByDay(args),
		onError: err => showErrorNotification(err.message),
	});
	public getResumeForPlaceInSections = new fetchModel<
		{ placeId: string; from: Date; until: Date },
		EventResumeSection[]
	>({
		fnPromise: args => enterprise.getResumeForPlaceInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEntranceResumeForPlaceInSections = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		EventResumeSection[]
	>({
		fnPromise: args =>
			enterprise.getEntranceResumeForPlaceInSections({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getClosingResumeReportForPlaceInSections = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		EventResumeSection[]
	>({
		fnPromise: args => enterprise.getClosingResumeReportForPlaceInSections(args),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public backofficeProductCmvEnhancedUnified: BackofficeProductCmvEnhanced[] = [];

	public enhanceBackofficeProductCMV = (
		data: BackofficeProductCmvWithQuantity[],
	): BackofficeProductCmvEnhanced[] => {
		const dataWithEnhancedData = data.map(p => ({
			prod: p,
			extraData: this.placeProductsById[p.productId],
		}));

		const filterProducts = dataWithEnhancedData.filter(r => r.extraData !== undefined);

		return filterProducts.map(({ extraData, prod }) => {
			const ube = extraData?.basicStorageUnit || "unit";
			const floatUnitCost =
				prod.quantity >= 0
					? Number(prod.unitCostValue || 0) *
					  (ube === "kilogram" || ube === "liter" ? 1000 : 1)
					: 0;
			const floatTotalCost =
				prod.quantity >= 0 ? Number(prod.unitCostValue || 0) * prod.quantity : 0;

			return {
				...prod,
				name: extraData?.name ?? "Nome indisponível",
				price: extraData?.value || 0,
				hasProductRule: extraData?.hasProductionRule || false,
				fiscalCode: extraData?.fiscalCode || null,
				ube,
				floatUnitCost,
				floatTotalCost,
			};
		});
	};

	public getBackofficeCurrentProductsCmvV2 = new fetchModel<
		{ placeId: string; productIds: string[] },
		BackofficeProductCmvWithQuantity[],
		BackofficeProductCmvEnhanced[]
	>({
		fnPromise: args => enterprise.getBackofficeCurrentProductsCmvV2(args),
		onSuccess: data => (this.backofficeProductCmvEnhancedUnified = data),
		onError: err => showErrorNotification(err.message),
		transform: data => this.enhanceBackofficeProductCMV(data),
	});

	public getBackofficeCurrentProductsCmvV2ForStorages = new fetchModel<
		{ placeId: string; storageIds: string[] },
		BackofficeProductCmvWithQuantity[],
		BackofficeProductCmvEnhanced[]
	>({
		fnPromise: args => enterprise.getBackofficeCurrentProductsCmvV2ForStorages(args),
		onSuccess: data => (this.backofficeProductCmvEnhancedUnified = data),
		onError: err => showErrorNotification(err.message),
		transform: data => this.enhanceBackofficeProductCMV(data),
	});

	public entrancesSoldAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		EntranceSold[]
	>({
		fnPromise: args =>
			enterprise.entrancesSoldAtPlace({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getEmployees = new fetchModel<{ pagination: Pagination }, EmployeeBaseResponse>({
		fnPromise: args => enterprise.getBaseEmployees(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEmployeesByDiscountCategory = new fetchModel<
		{ discountCategoryId: string },
		EmployeesByDiscountCategory[]
	>({
		fnPromise: args => enterprise.getEmployeesByDiscountCategory(args),
		onError: err => showErrorNotification(err.message),
	});

	public applyMountableProductsForProducts = new fetchModel<
		{ placeId: string; fromProductId: string; toProductIds: string[] },
		void
	>({
		fnPromise: args => enterprise.applyMountableProductsForProducts(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(
				t("place:products.duplicateMountableProduct.successMessage"),
			),
	});

	public getDiscountCategory = new fetchModel<
		{ placeId: string; pagination: Pagination; filters: DiscountCategoryFilters },
		DiscountCategoriesResponse
	>({
		fnPromise: args => enterprise.getDiscountCategories(args),
		onError: err => showErrorNotification(err.message),
	});

	public createDiscountCategory = new fetchModel<
		{
			discountCategory: CreateDiscountCategoryRequest;
			employeeIds: string[] | null;
		},
		DiscountCategoryResponse
	>({
		fnPromise: args => enterprise.createDiscountCategory(args),
		onError: err => showErrorNotification(err.message),
	});

	public updateDiscountCategory = new fetchModel<
		{
			discountCategoryId: string;
			discountCategory: UpdateDiscountCategoryRequest;
			employeeIds: string[] | null;
		},
		DiscountCategoryResponse
	>({
		fnPromise: args => enterprise.updateDiscountCategory(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteDiscountCategory = new fetchModel<
		{ discountCategoryId: string },
		DiscountCategoryResponse
	>({
		fnPromise: args => enterprise.deleteDiscountCategory(args),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.deleteDiscountCategory")),
		onError: err => showErrorNotification(err.message),
	});

	public activateDiscountCategory = new fetchModel<
		{
			discountCategoryId: string;
			placeIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.activateDiscountCategoryOnPlaces(args),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.activateDiscountCategory")),
		onError: err => showErrorNotification(err.message),
	});

	public disableDiscountCategory = new fetchModel<
		{
			discountCategoryId: string;
			placeIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.disableDiscountCategoryOnPlaces(args),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.disableDiscountCategory")),
		onError: err => showErrorNotification(err.message),
	});

	public getPendingOnlineRechargeReport = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		PendingOnlineRechargeReport
	>({
		fnPromise: args => enterprise.getPendingOnlineRechargeReport(args),
		onError: err => showErrorNotification(err.message),
	});

	// open sales
	public getAllOpenSalesAtPlaceByEmployee = new fetchModel<
		{ placeId: string; employeeId: string; since: Date; until: Date },
		OpenSale[]
	>({
		fnPromise: args => enterprise.getAllOpenSalesAtPlaceByEmployee(args),
		onError: err => showErrorNotification(err.message),
	});

	public getOpenSaleReportAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		OpenSaleReport[]
	>({
		fnPromise: args => enterprise.getOpenSaleReportAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAddressFromCep = new fetchModel<{ cep: string }, AddressFromCep>({
		fnPromise: args => enterprise.getAddressFromCep(args),
		onError: err => showErrorNotification(err.message),
	});

	public getLostReport = new fetchModel<
		{ placeId: string; storageIds?: string[] | null; since: Date; until: Date },
		ProductLostReport[]
	>({
		fnPromise: args => enterprise.getLostReportV2(args),
		onError: err => showErrorNotification(err.message),
	});

	public createManualLostReport = new fetchModel<
		{
			placeId: string;
			storageId: string;
			productId: string;
			count: number;
			date: Date;
		},
		void
	>({
		fnPromise: args => enterprise.lostBackofficeProductAtStorage(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.createManualLostReport")),
	});

	public userPromotions = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		UserUsedPromotion[]
	>({
		fnPromise: args =>
			enterprise.getUserPromotionsUsedAtPlace({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public testEmissionForProduct = new fetchModel<
		{ placeId: string; productId: string },
		void
	>({
		fnPromise: args => enterprise.testEmissionForProduct(args),
	});

	public getZigLocations = new fetchModel<{}, ZigLocation[]>({
		fnPromise: args => enterprise.getZigLocations(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlace = new fetchModel<{ placeId: string }, PlaceData>({
		fnPromise: args => enterprise.getPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public getProductsBurnedPaperTicketsForPlace = new fetchModel<
		{ placeId: string; since?: Date | null | undefined; until?: Date | null | undefined },
		ProductBurnedTicketSold[]
	>({
		fnPromise: args => enterprise.getProductsBurnedPaperTicketsForPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	/**
	 * Step: 0
	 * Route: /place/:id/settings
	 */
	public getPlaceSettingsGeneralSection = new fetchModel<
		{ placeId: string },
		PlaceSettingsGeneralSectionResponse
	>({
		fnPromise: args => enterprise.getPlaceSettingsGeneralSection(args),
		onError: err => showErrorNotification(err.message),
	});

	public getProductsAvailableToZigTag = new fetchModel<
		{ placeId: string },
		ProductToZigTag[]
	>({
		fnPromise: args => enterprise.getProductsAvailableToZigTag(args),
		onError: err => showErrorNotification(err.message),
	});

	public updatePlaceSettingsGeneralSection = new fetchModel<
		{
			placeId: string;
			data: PlaceSettingsGeneralSectionRequest;
		},
		PlaceSettingsGeneralSectionResponse
	>({
		fnPromise: args => enterprise.updatePlaceSettingsGeneralSection(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.updatePlaceSettingsGeneralSection")),
	});

	/**
	 * Step: 1
	 * Route: /place/:id/settings
	 */
	public getPlaceSettingsFichaSection = new fetchModel<
		{ placeId: string },
		PlaceSettingsFichaSectionResponse
	>({
		fnPromise: args => enterprise.getPlaceSettingsFichaSection(args),
		onError: err => showErrorNotification(err.message),
	});

	public updatePlaceSettingsFichaSection = new fetchModel<
		{
			placeId: string;
			data: PlaceSettingsFichaSectionRequest;
		},
		PlaceSettingsFichaSectionResponse
	>({
		fnPromise: args => enterprise.updatePlaceSettingsFichaSection(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.updatePlaceSettingsFichaSection")),
	});

	/**
	 * Step: 3
	 * Route: /place/:id/settings
	 */
	public getPlaceSettingsPlaceFeatureSection = new fetchModel<
		{ placeId: string },
		PlaceSettingsPlaceFeatureSectionResponse
	>({
		fnPromise: args => enterprise.getPlaceSettingsPlaceFeatureSection(args),
		onError: err => showErrorNotification(err.message),
	});

	public updatePlaceSettingsPlaceFeatureSection = new fetchModel<
		{
			placeId: string;
			data: PlaceSettingsPlaceFeatureSectionRequest;
		},
		PlaceSettingsPlaceFeatureSectionResponse
	>({
		fnPromise: args => enterprise.updatePlaceSettingsPlaceFeatureSection(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(
				t("store:placeStore.updatePlaceSettingsPlaceFeatureSection"),
			),
	});

	public getPlaceEverestConfig = new fetchModel<
		{
			placeId: string;
		},
		EverestConfig | null
	>({
		fnPromise: args => enterprise.getPlaceEverestConfig(args),
		onError: err => showErrorNotification(err.message),
	});

	public editPlaceEverestConfig = new fetchModel<
		{
			placeId: string;
			config?: EverestConfig | null;
		},
		void
	>({
		fnPromise: args => enterprise.editPlaceEverestConfig(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("store:placeStore.editEverestConfig")),
	});

	/**
	 * Step: 4
	 * Route: /place/:id/settings
	 */
	public getPlaceSettingsPdvSection = new fetchModel<
		{ placeId: string },
		PlaceSettingsPdvSectionResponse
	>({
		fnPromise: args => enterprise.getPlaceSettingsPdvSection(args),
		onError: err => showErrorNotification(err.message),
	});

	public updatePlaceSettingsPdvSection = new fetchModel<
		{
			placeId: string;
			data: PlaceSettingsPdvSectionRequest;
		},
		PlaceSettingsPdvSectionReturn
	>({
		fnPromise: args => enterprise.updatePlaceSettingsPdvSection(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.updatePlaceSettingsPDVSection")),
	});

	public getPlaceSettingsTotemSection = new fetchModel<
		{
			placeId: string;
		},
		TotemPlaceConfigResponse
	>({
		fnPromise: args => enterprise.getPlaceSettingsTotemSection(args),
		onError: err => showErrorNotification(err.message),
	});

	public updatePlaceSettingsTotemSection = new fetchModel<
		{
			placeId: string;
			data: TotemConfigsRequest;
		},
		TotemPlaceConfigResponse
	>({
		fnPromise: args => enterprise.updatePlaceSettingsTotemSection(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.updatePlaceSettingsTotemSection")),
	});

	public createOpenBillChargeByPlace = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			userId: string;
			shouldChargeTip: boolean;
		},
		OpenBillCharge | null
	>({
		fnPromise: args => enterprise.createOpenBillChargeByPlace(args),
	});

	public getOpenBillChargeDetails = new fetchModel<
		{
			openBillChargeId: string;
			placeId: string;
		},
		OpenBillChargeDetails | null
	>({
		fnPromise: args => enterprise.getOpenBillChargeDetails(args),
	});

	public sendOpenBillMail = new fetchModel<
		{
			userEmail: string;
			openBillChargeId: string;
		},
		void
	>({
		fnPromise: args => enterprise.sendOpenBillChargeByEmail(args),
		onError: err => showErrorNotification(err.message),
	});

	public payBillWithPix = new fetchModel<
		{
			userId: string;
			placeId: string;
			payments: BillPayment[];
			since: Date;
			until: Date;
			reason?: string | null;
		},
		PixCharge | null
	>({
		fnPromise: args => enterprise.payBillAtPlaceInPeriodWithMultiplePayments(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("store:placeStore.createPixCharge")),
	});

	public getAppTransactionsAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date; pagination: Pagination },
		AppTransactionDetailsPaginationResponse
	>({
		fnPromise: args => enterprise.getAppTransactionsAtPlace(args),
	});

	public getAppTotalizerByPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date; pagination?: Pagination | null },
		AppTransactionsTotalizer
	>({
		fnPromise: args => enterprise.getAppTotalizerByPlace(args),
	});

	public getTotemTransactions = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			pagination: Pagination;
			filters: TotemTransactionFilters;
		},
		TotemTransactionResponse
	>({
		fnPromise: args => enterprise.getTotemTransactions(args),
	});

	public getTotemTransactionTotalizer = new fetchModel<
		{ placeId: string; since: Date; until: Date; pagination?: Pagination | null },
		TotemTransactionTotalizerResponse
	>({
		fnPromise: args => enterprise.getTotemTransactionTotalizer(args),
	});

	public exportAllTransactionProductsXlsxPlace = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		string
	>({
		fnPromise: args => enterprise.exportAllTransactionProductsXlsxPlace(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public getOpenBillDetailsByUserInPlace = new fetchModel<
		{
			userId: string;
			placeId: string;
			since: Date;
			until: Date;
		},
		OpenBillChargeDetails
	>({
		fnPromise: args => enterprise.getOpenBillDetailsByUserInPlace(args),
	});

	public getPendingPlaceContracts = new fetchModel<
		{
			placeId: string;
		},
		ListPendingPlaceContracts
	>({
		fnPromise: args => enterprise.getPendingPlaceContracts(args),
	});

	public signPlaceContracts = new fetchModel<
		{
			contractIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.signPlaceContracts(args),
		onError: err => showErrorNotification(err.message),
	});

	public confirmSignPlaceContracts = new fetchModel<
		{
			code: string;
			placeId: string;
		},
		void
	>({
		fnPromise: args => enterprise.confirmSignPlaceContracts(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:placeStore.confirmSignPlaceContracts")),
	});

	public get isCurrentPlaceInternational() {
		const countryISO3 = this.place?.countryISO3;
		if (countryISO3) {
			return countryISO3 !== "BRA";
		}
		return false; // assume it is not international by default
	}

	public isCurrentPlaceOfCountry(iso3: "BRA" | "MEX" | "PRT" | "BEL") {
		return iso3 === this.place?.countryISO3;
	}

	public getSellerTipReportForPlace = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
			filters: EmployeeTipReportFilters;
		},
		EmployeeTipReport[]
	>({
		fnPromise: args => enterprise.getSellerTipReportForPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceClientsByEntrance = new fetchModel<
		{
			placeId: string;
			filters: UsersEntrancesConsumedReportFilters;
			pagination: Pagination;
		},
		UsersEntrancesConsumedReportResponse
	>({
		fnPromise: args => enterprise.usersEntrancesConsumed(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceClientsByEntranceOverview = new fetchModel<
		{ placeId: string; filters: UsersEntrancesConsumedReportFilters },
		UsersEntrancesConsumedOverview
	>({
		fnPromise: args => enterprise.usersEntrancesConsumedOverview(args),
		onError: err => showErrorNotification(err.message),
	});

	public getProductRankingReport = new fetchModel<
		{
			eventId?: string | null;
			placeId?: string | null;
			period?: PeriodTime | null;
		},
		ProductRankingReport[]
	>({
		fnPromise: args => enterprise.productRankingReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getClientOnlineFichaHistoryAtEvent = new fetchModel<
		{
			userId: string;
			eventId: string;
		},
		ClientOnlineFichaHistory[]
	>({
		fnPromise: args => enterprise.getClientOnlineFichaHistoryAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getProductRankingEmployees = new fetchModel<
		{
			productIds: string[];
			eventId?: string | null;
			placeId?: string | null;
			period?: PeriodTime | null;
		},
		EmployeeProductRankingReport[]
	>({
		fnPromise: args => enterprise.productRankingEmployees(args),
		onError: err => showErrorNotification(err.message),
	});

	public transactionTransfersAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		TransactionTransfer[]
	>({
		fnPromise: args => enterprise.getTransactionTransfersAtPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public listPlaceContracts = new fetchModel<
		{
			placeId: string;
			pagination: Pagination;
			filters: PlaceContractListFilters;
		},
		PlaceContractListPaginated
	>({
		fnPromise: args => enterprise.listPlaceContracts(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceContract = new fetchModel<
		{
			contractId: string;
		},
		PlaceContract
	>({
		fnPromise: args => enterprise.getPlaceContract(args),
		onError: err => showErrorNotification(err.message),
	});

	public downloadFilesFromContracts = new fetchModel<
		{
			contractIds: string[];
		},
		string
	>({
		fnPromise: args => enterprise.downloadFilesFromContracts(args),
		onSuccess: url => window.open(url, "_blank"),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceZendeskTickets = new fetchModel<
		{
			placeId: string;
			filters: GetPlaceZendeskTicketsFilters;
			pagination: Pagination;
		},
		ZendeskTicketsPaginationResponse
	>({
		fnPromise: args => enterprise.getPlaceZendeskTickets(args),
		onError: err => showErrorNotification(err.message),
	});

	public listRefundReasons = new fetchModel<
		{
			placeId: string;
		},
		RefundReason[]
	>({
		fnPromise: args => enterprise.listRefundReasons(args),
		onError: err => showErrorNotification(err.message),
	});

	public createRefundReasons = new fetchModel<
		{
			placeId: string;
			refundReasons: CreateRefundReason[];
		},
		RefundReasonResult
	>({
		fnPromise: args => enterprise.createRefundReasons(args),
		onError: err => showErrorNotification(err.message),
	});

	public editRefundReasons = new fetchModel<
		{
			placeId: string;
			refundReasons: EditRefundReason[];
		},
		RefundReasonResult
	>({
		fnPromise: args => enterprise.editRefundReasons(args),
		onError: err => showErrorNotification(err.message),
	});

	public deleteRefundReasons = new fetchModel<
		{
			placeId: string;
			refundReasonIds: string[];
		},
		void
	>({
		fnPromise: args => enterprise.deleteRefundReasons(args),
		onError: err => showErrorNotification(err.message),
	});

	public getUsersFromProductsXls = new fetchModel<
		{
			placeId: string;
			productIds: string[];
			period: PeriodTime;
			limit: number;
			offset: number;
		},
		string
	>({
		fnPromise: args => enterprise.getUsersFromProductsXls(args),
		onError: err => showErrorNotification(err.message),
	});

	public getSingleProductReturns = new fetchModel<
		{
			placeId: string;
			period: PeriodTime;
		},
		SingleProductReturnResponse[]
	>({
		fnPromise: args => enterprise.getSingleProductReturns(args),
		onError: err => showErrorNotification(err.message),
	});

	public getSingleProductReturnsByEvent = new fetchModel<
		{
			eventId: string;
			placeId: string;
		},
		SingleProductReturnResponse[]
	>({
		fnPromise: args => enterprise.getSingleProductReturnsByEvent(args),
		onError: err => showErrorNotification(err.message),
	});
}
