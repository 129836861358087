import { UserBonusTableTranslations } from "./user-bonus-table-pt-br";

export const USER_BONUS_TABLE_EN_US: UserBonusTableTranslations = {
	title: "Bonus",
	total: "Total of {{category}}",
	noCategory: "No category",
	columns: {
		total: "Total",
		givenBy: "Given by",
		client: "Responsible Employee",
		date: "Date",
		reason: "Reason",
		category: "Category",
		receivedValue: "Received value",
		spentValue: "Value spent in the period",
		spentValueInOtherEvents: "Value spent in another period",
	},
	minimumConsumptionReport: {
		title: "Anticipated consumption",
	},
	filenameBonus: "bonus-granted",
	filenameAnticipated: "anticipated-consumption",
	filenameConsumption: "consumption",
	select: "Select a category",
	selectNotFound: "No category found",
};
