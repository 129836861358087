export const syncMenu = {
	title: "Cardápio de Delivery ({{externalStoreType}})",
	description:
		"Selecione na lista da Zig o produto a ser associado com o item do {{externalStoreType}}. Os itens se tornarão únicos",
	menuNameExternalType: "Cardápio de Delivery ({{externalStoreType}})",
	transferProducts: {
		warning:
			"{{amount}} produtos não foram sincronizados. Gostaria de importá-los para o cardápio de Delivery?",
		description: "Selecione os itens que gostaria de importar, ou finalize para seguir.",
		amountItemsSelected: "<strong>{{amount}}</strong> itens selecionados",
		completeBtn: "Concluir sem importar",
		importItemsBtn: "Importar itens",
	},
	bindProduct: {
		searchPlaceholder: "Busque outro produto para sincronizar",
		allProducts: "Todos os produtos",
		notProductFound: "Nenhum produto encontrado",
		errorProductFound: "Ocorreu um erro ao buscar os produtos",
		similarity: {
			title: "Nenhum produto similar encontrado",
			description:
				"Faça uma pesquisa na barra de busca acima, ou passe para o próximo produto.<br/>Você poderá importar os produtos sem similaridade ao final da sincronização.",
		},
		saveLater: "Salvar e continuar mais tarde",
		undo: "Desfazer ação anterior",
		completeSync: "Concluir sincronização",
		skuInfo: {
			title: "O SKU desse produto será substituído",
			description:
				"O SKU da ZIG (produto selecionado ao lado) irá se sobrepor em ambos os cardápios. É isso que vai permitir uma gestão centralizada.",
		},
		undoModal: {
			title: "Desfazer sincronização?",
			description:
				"Ao confirmar, o voltaremos para o produto anterior e a sincronização será desfeita.",
			okText: "Quero desfazer",
			cancelText: "Cancelar",
		},
	},
	productCard: {
		descriptionLabel: "Descrição",
		active: "Ativo",
		inactive: "Inativo",
	},
	transferProductSuccess: {
		title: "Integração concluída",
		description:
			"Sua integração foi concluída e seus produtos foram sincronizados com sucesso!",
		button: "Ver produtos e cardápios",
	},
	syncMenuSuccess: {
		title: "Integração concluída",
		description:
			"Sua integração foi concluída e seus produtos foram sincronizados com sucesso!",
		button: "Ver produtos e cardápios",
		continue: "Ver produtos não importados",
	},
	errorContent: {
		title: "Não foi possível importar os produtos",
		description:
			"Algo inesperado aconteceu e não conseguimos concluir a importação dos produtos. Conclua sua integração e finalize seu cardápio mais tarde.",
		completeBtn: "Concluir sincronização sem importar",
		retryBtn: "Tentar importar novamente",
	},
	onboarding: {
		step1: {
			title: "Iremos exibir um produto de cada vez",
			description:
				"Cada produto importado será exibido ao lado de sugestões equivalentes no catálogo de produtos da ZIG. Você irá selecionar o produto ideal para unificar.",
		},
		step2: {
			title: "Um único SKU",
			description:
				"Ao selecionar o produto, o SKU da ZIG prevalecerá e você poderá fazer uma gestão centralizada no nosso dashboard.",
		},
		step3: {
			title: "Importe o que não estiver ainda na Zig",
			description:
				"Quando terminar de sincronizar os itens equivalentes, você poderá importar os produtos que não foram identificados no catálogo da ZIG. Assim, você terá todos os seus produtos em um só lugar!",
		},
		skipBtn: "Pular",
		submitBtn: "Começar sincronização",
	},
};
