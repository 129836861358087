import { BonusReportAtEventTranslations } from "./bonus-report-at-event-pt-br";

export const BONUS_REPORT_AT_EVENT_FR_FR: BonusReportAtEventTranslations = {
	title: "Bonus",
	description: "Voir tous les rapports de concession et d'utilisation des bonus",
	tabs: {
		given: "Bonus Accordé",
		used: "Bonus Utilisé",
	},
};
