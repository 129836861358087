import { tableRangeSettings } from "./table-range-settings";

export const bars = {
	search: "Search",
	searchBarPlaceholder: "Search by bar name",
	searchProductPlaceholder: "Search by product",
	searchCategoryPlaceholder: "Search by product category",
	manageBar: "Manage Bar",
	menuTooltipActive: "Menu linked to this bar and active",
	menuTooltipInactive: "Menu linked to this bar and inactive",
	emptyMessage: "No items to show",
	addBar: "Add bar",
	editBar: "Edit bar",
	deleteMessage:
		"The deletion will impact the operation: the products will no longer be displayed and the printers will be disassociated. This action cannot be undone. Do you want to continue?",
	delete: "Delete",
	cancel: "Cancel",
	deleteBar: "Delete bar",
	yesDeleteBar: "Yes, delete bar",
	stock: "Inventory:",
	printerIps: "Printer IP",
	activeProducts: "Active Products",
	inactiveProducts: "Inactive Products",
	notifications: {
		productActivated: "Product activated successfully",
		productDeactivated: "Product deactivated successfully",
	},
	transfer: {
		noItems: "No items available",
		noCategoryFound: 'No category found for "{{category}}"',
		noProductFound: 'No product found for "{{product}}"',
		noResultsFound:
			'No results found for category "{{category}}" and product "{{product}}"',
		selectProductsToActivate: "Select products to activate",
		selectProductsToDeactivate: "Select products to deactivate",
		inactiveProductTooltip:
			"This product is not active in the place and will not be available for sale at POS or printed in delivery orders",
	},
	modal: {
		barName: "Bar name",
		barStock: "Stock",
		addNewIp: "Add printer IP (Optional)",
		printerType: "Type",
		printerOffline: "Offline",
		numberOfLanes: "Number of lanes",
		numberOfLanesDescription: "Number of copies printed on the printer after the order.",
		menu: "Menu",
		logoFicha: "Logo on ficha",
		logoFichaInfo:
			"If you don't select a logo for the bar's token, the printed logo on the token will be {{placeName}}'s",
		logoFichaDescription: "Image that will appear printed on the token.",
		stockDescription: "The selected stock will be used by this bar.",
		menuDescription: "The selected menu will be used by this bar.",
		barNamePlaceholder: "Enter the bar name",
		barStockPlaceholder: "Select",
		menuPlaceholder: "Select one or more menus",
		saveModifiedBar: "Save changes",
		addBar: "Add bar",
		errors: {
			printerTypeRequired: "Printer type field is required",
			printerNameRequired: "Printer name field is required",
			printerStockRequired: "Printer stock field is required",
			printerIpFormat: "The IP field must be valid (eg: 192.168.1.1)",
			pagesToPrintMin: "The number of copies must be at least {{min}}",
			pagesToPrintMax: "The number of copies must be at most {{max}}",
			validIp: "The IP field must be valid",
			requiredField: "Field required",
			maxLength: "The field must be no more than {{max}} characters",
		},
		addImage: "Add Image",
		fileTypeInfo: "File type: jpeg, pjpeg, png",
	},
	tableRangeSettings,
};
