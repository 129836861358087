import { UserBonusTableTranslations } from "./user-bonus-table-pt-br";

export const USER_BONUS_TABLE_ES_MX: UserBonusTableTranslations = {
	title: "Bono",
	total: "Total de {{category}}",
	noCategory: "Sin categoría",
	columns: {
		total: "Total",
		givenBy: "Dado por",
		client: "Empleado Responsable",
		date: "Fecha",
		reason: "Razón",
		category: "Categoría",
		receivedValue: "Valor recibido",
		spentValue: "Valor gastado en el período",
		spentValueInOtherEvents: "Valor gastado en otro período",
	},
	minimumConsumptionReport: {
		title: "Consumo anticipado",
	},
	filenameBonus: "bono-otorgado",
	filenameAnticipated: "consumo-anticipado",
	filenameConsumption: "consumo",
	select: "Seleccione una categoría",
	selectNotFound: "No se encontró ninguna categoría",
};
