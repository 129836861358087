export const BONUS_REPORT_PT_BR = {
	title: "Bônus",
	description: "Veja todos os relatórios de concessão e uso de bônus",
	tabs: {
		given: "Bônus Concedido",
		used: "Bônus Utilizado",
	},
};

export type BonusReportTranslations = typeof BONUS_REPORT_PT_BR;
