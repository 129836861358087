export const vendors = {
	title: "Vendors",
	searchNamePlaceholder: "Buscar por nome/login do vendor",
	header: {
		create: "Criar vendor",
		batchPermissions: "Permissão em lote",
	},
	list: {
		vendors: "Vendors",
		rent: "Aluguel",
		revenuePercentageFee: "% sobre a venda",
		tipPercentageFee: "Taxa de serviço",
		places: "Locais",
		fiscalProfile: "Perfil fiscal",
		login: "Login",
		paymentDaysOffset: "Tempo de repasse",
		productsSpecialFee: "Taxa especial",
		product: "produto",
		products: "produtos",
		actions: {
			editVendor: "Editar vendor",
			deleteVendor: "Remover vendor",
		},
		place: "Local",
		bars: "Bares",
	},
	modal: {
		close: "Fechar",
		prevStep: "Etapa anterior",
		nextStep: "Próxima etapa",
		steps: {
			dataCollection: "Dados do vendor",
			barSelection: "Dados de locais e bares",
			feesCalculation: "Taxas e perfil fiscal",
			guidance: "Guia",
		},
		delete: {
			title: "Remover vendor",
			description: "Tem certeza que deseja remover permanentemente este vendor?",
			buttonConfirm: "Confirmar",
		},
		mode: {
			create: "Criar novo vendor",
			update: "Editar Vendor",
			updateDataCollection: "Editar dados do vendor",
			updateLinkingBarsAndLocations: "Editar locais e bares",
			updateFeesTaxProfile: "Editar taxas e perfil fiscal",
		},
		submit: {
			create: "Criar vendor",
			update: "Salvar",
		},
		form: {
			required: "Campo obrigatório",
			nonnegative: "O valor deve ser maior ou igual a zero",
			name: {
				label: "Nome",
				validation: "O nome não pode ser vazio",
				placeholder: "Digite o nome do vendor",
				nonempty: "Nome não pode ser vazio",
			},
			login: {
				label: "Login",
				validation: "O login não pode ser vazio",
				placeholder: "Digite o login do vendor",
				nonempty: "Login não pode ser vazio",
				emailRegexValidation: "O login não deve ser um e-mail",
				charactersRegexValidation:
					"O login não deve conter letra maiúscula e caracteres especiais",
			},
			password: {
				label: "Senha",
				validation: "A senha não pode ser vazio",
				placeholder: "Digite a senha do vendor",
				required: "Senha obrigatória",
				nonempty: "Senha não pode ser vazia",
			},
			places: {
				label: "Locais",
				validation: "Selecione pelo menos um local",
				placeholder: "Selecione os locais em que o vendor vai estar ativo",
				selectAll: "Selecionar todos",
			},
			bars: {
				label: "Bares",
				validation: "Selecione pelo menos um bar",
				placeholder: "Selecione os bares do vendor",
				noBars: "Nenhum bar selecionado",
			},
			dailyRentalFeeValue: {
				label: "Taxa fixa por evento",
			},
			revenuePercentageFee: {
				label: "Taxa percentual sobre faturamento",
			},
			tipPercentageFee: {
				label: "Taxa percentual sobre serviço",
			},
			paymentDaysOffset: {
				label: "Dias para realizar pagamento (D+)",
				maxValidation: "Deve estar entre 0 e {{count}}",
			},
			anticipationFee: {
				label: "Taxa de antecipação",
			},
			fiscalProfileId: {
				label: "Perfil fiscal",
				placeholder: "Selecione o perfil fiscal",
			},
			isFinancialVendor: {
				label: "Fechamentos financeiros",
				description: "Gerar fechamentos financeiros para este vendor",
			},
			paymentMethodFees: {
				credit: "Taxa sobre pagamento em crédito",
				debit: "Taxa sobre pagamento em débito",
				pix: "Taxa sobre pagamento em pix",
			},
			productFees: {
				productFee: "Taxa especial por produto",
				productFeePlaceholder: "Pesquise um produto",
				percent: "Percentual",
				button: "Adicionar outro produto",
			},
			featureAlert:
				"A sobra de recarga só será distribuída caso o bar principal do caixa que fez a recarga seja do vendor. Incide taxa sobre vendas sobre a sobra de recarga do vendor.",
			floorComplement: "Mínimo garantido por evento",
		},
		guidance: {
			vendorData: {
				title: "Editar dados do vendor",
				description: "Nome, login e senha",
			},
			linkingBarsAndLocations: {
				title: "Editar locais e bares",
				description: "Selecione os locais do vendor e seus bares",
			},
			feesTaxProfile: {
				title: "Editar taxas e perfil fiscal",
				description:
					"Taxas diárias, percentual sobre faturamento, percentual sobre serviço, dia de pagamento e perfil fiscal",
			},
		},
		productModal: {
			title: "Produtos e taxas especiais",
			product: "Produto",
			fee: "Taxa",
			closeConfirmTitle: "Descartar alterações em taxas especiais",
			closeConfirmSubtitle:
				'Você fez alterações e elas só serão salvas se você clicar em "salvar alterações". Deseja descartar as alterações feitas ou voltar para a edição das taxas?',
			closeConfirmCancelActionText: "Voltar para edição",
			closeConfirmConfirmActionText: "Descartar alterações",
			save: "Salvar alterações",
			deleteConfirmTitle: "Remover taxa especial",
			deleteConfirmSubtitle: 'Deseja remover taxa especial de "{{value}}"?',
			deleteConfirmBoldSubtitle: "Essa ação não pode ser desfeita",
		},
	},
	modalPermissions: {
		single: {
			edit: "Editar {{vendorName}}",
			withdraw: "Bloquear saque",
			anticipation: "Permitir solicitação de antecipação",
			remove: "Remover vendor",
		},
		multiple: {
			title: "{{count}} vendors selecionados",
			blockWithdraw: {
				title: "Permitir saque",
				subtitle: "Bloquear saque de {{count}} vendors",
				description: "Essa ação bloqueará o saque dos vendors abaixo em todos os places.",
				confirm: "Confirmar bloqueio de saque",
			},
			canAnticipate: {
				title: "Permitir antecipação",
				subtitle: "Bloquear antecipação de {{count}} vendors",
				description:
					"Essa ação bloqueará a antecipação dos vendors abaixo em todos os places.",
				confirm: "Confirmar bloqueio de antecipação",
			},
			cancel: "Fechar",
			applySelection: "Aplicar seleção",
		},
		thisAction: "Essa ação irá",
		vendorsBelow: "dos vendors abaixo em todos os places",
		featureTitleBothAllowed: "Permitir saque e antecipação",
		featureTitleBothBlocked: "Bloquear saque e antecipação",
		featureTitleMixed: "{{withdraw}} saque e {{anticipation}} antecipação",
		block: "Bloquear",
		allow: "Permitir",
		vendorsCount_one: "de 01 vendor",
		vendorsCount_other: "de {{count}} vendors",
	},
};
