export const syncMenu = {
	title: "Menú de Delivery ({{externalStoreType}})",
	description:
		"Seleccione en la lista de Zig el producto que desea asociar con el item de {{externalStoreType}}. Los artículos se volverán únicos.",
	menuNameByExternalType: "Menú de entrega ({{externalStoreType}})",
	transferProducts: {
		warning:
			"{{amount}} productos no se han sincronizado. ¿Desea importarlos al menú de Delivery?",
		description: "Seleccione los artículos que desea importar, o termine para continuar.",
		amountItemsSelected: "<strong>{{amount}}</strong> artículos seleccionados",
		completeBtn: "Terminar sin importar",
		importItemsBtn: "Importar artículos",
	},
	bindProduct: {
		searchPlaceholder: "Busque otro producto para sincronizar",
		allProducts: "Todos los productos",
		notProductFound: "No se encontró ningún producto",
		errorProductFound: "Se produjo un error al buscar productos.",
		similarity: {
			title: "No se encontraron productos similares",
			description:
				"Busque en la barra de búsqueda de arriba o salte al siguiente producto.<br/>Podrás importar los productos sin similitud al finalizar la sincronización.",
		},
		saveLater: "Guardar y continuar más tarde",
		undo: "Deshacer acción anterior",
		completeSync: "Terminar sincronización",
		skuInfo: {
			title: "El SKU de este producto se reemplazará",
			description:
				"El SKU de Zig (producto seleccionado al lado) se sobrepasará en ambos los menús. Esto permitirá una gestión centralizada.",
		},
		undoModal: {
			title: "¿Deshacer sincronización?",
			description:
				"Al confirmar, regresaremos al producto anterior y la sincronización se deshará.",
			okText: "Quiero deshacer",
			cancelText: "Cancelar",
		},
	},
	productCard: {
		descriptionLabel: "Descripción",
		active: "Activo",
		inactive: "Inactivo",
	},
	transferProductSuccess: {
		title: "Integración concluida",
		description:
			"La integración se ha completado y sus productos se han sincronizado con éxito.",
		button: "Ver productos y menús",
	},
	syncMenuSuccess: {
		title: "Integración concluida",
		description:
			"La integración se ha completado y sus productos se han sincronizado con éxito.",
		button: "Ver productos y menús",
		continue: "Ver productos no importados",
	},
	errorContent: {
		title: "No se pudo importar los productos",
		description:
			"Algo inesperado sucedió y no pudimos completar la importación de los productos. Complete su integración y finalice su menú más tarde.",
		completeBtn: "Terminar sincronización sin importar",
		retryBtn: "Intentar importar nuevamente",
	},
	onboarding: {
		step1: {
			title: "Mostraremos un producto a la vez",
			description:
				"Cada producto importado se mostrará junto a sugerencias equivalentes en el catálogo de productos de ZIG. Deberá seleccionar el producto ideal para unificar.",
		},
		step2: {
			title: "Un solo SKU",
			description:
				"Al seleccionar el producto, el SKU de ZIG prevalecerá y podrá gestionar de manera centralizada en nuestro panel.",
		},
		step3: {
			title: "Importe lo que aún no está en Zig",
			description:
				"Cuando termine de sincronizar los elementos equivalentes, podrá importar los productos que no fueron identificados en el catálogo de ZIG. ¡Así tendrá todos sus productos en un solo lugar!",
		},
		skipBtn: "Saltar",
		submitBtn: "Comenzar sincronización",
	},
};
